import React, {useState, useEffect} from 'react';
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';


function PointsBreakdown({config, onHide}) {
    const [points, setPoints] = useState([]);

    useEffect(() => {
        if(config.show) {
            console.log(config);
        }
        if(config.show && config.questionId !== undefined && config.answerId !== undefined) {
            if(config.submissionId !== undefined) {
                fetchSubmissionAnswerPoints();
            } else {
                fetchAnswerPoints();
            }
        }
    }, [config]);

    const fetchSubmissionAnswerPoints = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				submissionId: config.submissionId,
				questionId: config.questionId,
				answerId: config.answerId
            })
        };
    
		fetch(apiUrl + `/app/submission/answer/points`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setPoints(data);
			})
			.catch((err) => {
				console.log(err);
			});
    }

    const fetchAnswerPoints = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: config.questionId,
				answerId: config.answerId
            })
        };
    
		fetch(apiUrl + `/app/form/answer/points`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setPoints(data);
			})
			.catch((err) => {
				console.log(err);
			});
    }
    
    return (
        <Modal show={config.show} onHide={onHide} closeOnOverlayClick={true}>
            <h2>Points Breakdown</h2>
            
            <div className='flex flex-column gap-1'>
            {points.map(p => (
                <div className='flex'>
                    <div className='flex-grow-1'>
                        <div><b>{p.label}</b></div>
                        <div className='subtext'>{p.description}</div>
                    </div>
                    <div>{p.points.toFixed(1)}</div>
                </div>
            ))}
                </div>

            <div className="modal-footer">
                <Button onClick={onHide}>Close</Button>
            </div>
      </Modal>

     );
    
}

export default PointsBreakdown;