import React, { useReducer, useState, useEffect } from 'react';

import Loader from '../../../../components/Loader/Loader';

import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';
import Input from '../../../../atoms/Input/Input';
import Select from '../../../../atoms/Select/Select';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import EmergencyIcon from '@mui/icons-material/Emergency';

import './ScheduleSettings.css';

function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action.hasOwnProperty('payload')})`);   
    
    let newState = structuredClone(state);
    try {
        let i = action?.payload?.index;

        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'reset':
                return [];

            case 'add':
                newState.push({
                    action: 'add',
                    ...action?.payload
                });
                return newState;

            case 'delete':
                if(newState[i].action === 'add') {
                    newState.splice(i, 1);
                } else {
                    newState[i].action = 'delete';
                }
                return newState;

            case 'restore':
                delete newState[i].action;
                return newState;


            case 'update':
                console.log(`Update ${action.payload.property} to ${action.payload.value}`);   
                if(action.payload.property === 'Date') {
                    newState[i].date = action.payload.value;

                } else if(action.payload.property === 'Type') {
                    newState[i].type = action.payload.value;

                } else if(action.payload.property === 'Meeting Key') {
                    newState[i].meetingKey = action.payload.value;

                } else if(action.payload.property === 'Session Key') {
                    newState[i].sessionKey = action.payload.value;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                } 
                return newState;
            
            // case 'clear_matched_data':
            //     newState.forEach(s => { 
            //         if (s.hasOwnProperty('matchedDate')) { delete s['matchedDate']; }
            //         if (s.hasOwnProperty('matchedSessionKey')) { delete s['matchedSessionKey']; }
            //         if (s.hasOwnProperty('matchedMeetingKey')) { delete s['matchedMeetingKey']; }
            //     });
            //     return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}

function ScheduleSettings({config, onHide}) {
    const [hasSaved, setHasSaved] = useState(false);
    const [dbData, setDbData] = useState([]);
    const [events, dispatch] = useReducer(reducer, []);
    const [openF1Sessions, setOpenF1Sessions] = useState([]);
    const [loader, setLoader] = useState({show: false, text: ''});
    const [eventTypes, setEventTypes] = useState([
        'Grand Prix',
        'Qualifying',
        'Sprint',
        'Sprint Qualifying',
        'Practice 1',
        'Practice 2',
        'Practice 3'
    ]);
    
    useEffect(() => {
        if(config.show) {
            fetchEvent();
        } else {
            dispatch({type: 'reset'});
        }

    }, [config]);

    

    const fetchEvent = async () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            eventId: config.eventId
          })
        };
        
        try {
            const response = await fetch(apiUrl + `/app/ref/event`, requestOptions);
            const data = await response.json();            
            fetchRelatedEvents(data);

        } catch (err) {
            console.log(err);
        }
    }

    const fetchRelatedEvents = async (event) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId
            })
        };
        try {
            const response = await fetch(apiUrl + "/app/ref/relatedEvents", requestOptions);
            const eventData = await response.json();
            
            const orderedEvents = eventData.sort((a, b) => b.date - a.date);
            
            setDbData(orderedEvents);
            dispatch({type: 'initialize', payload: orderedEvents});
            console.log('fetchEvents: /app/ref/relatedEvents', orderedEvents);
            fetchOpenF1Sessions(event, orderedEvents);
            
        } catch (err) {
            console.log(err);
        }
    }
    
    const fetchOpenF1Sessions = async (event, eventData) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                year: event.season,
                eventName: event.name
            })
        };

        setLoader({show: true, text: 'Fetching from Open F1...'});

        try {
            const response = await fetch(apiUrl + "/import/fetchSessions", requestOptions);
            const openF1Data = await response.json();

            /*  Openf1 returns date with different string format
                    Example: 2025-03-16T04:00:00+00:00
                Reformat to 
            */
            openF1Data.forEach(d => {
                d.date = new Date(d.date).toISOString();
            }); 
            const orderedOpenF1Data = openF1Data.sort((a, b) => b.date - a.date);
            console.log('fetchOpenF1Sessions: /import/fetchSessions', orderedOpenF1Data);
            setLoader({show: false});
            addMissingOpenF1Sessions(event, eventData, orderedOpenF1Data);
            setOpenF1Sessions(orderedOpenF1Data);

            // compareToOpenF1(event, eventData, openF1Data);
        } catch (err) {
            setLoader({show: false});
            console.log(err);
        }
    }

    const addMissingOpenF1Sessions = (event, eventData, openF1Data) => {
        for (let j = 0; j < openF1Data.length; j++) {
            if(!eventData.some((event) => event['type'] === openF1Data[j].type)) {

                const newEvent = {
                    name: event.name,
                    season: event.season,
                    round: event.round,
                    circuitId: event.circuitId,
                    type: openF1Data[j].type, 
                    date: openF1Data[j].date, 
                    meetingKey: openF1Data[j].meetingKey, 
                    sessionKey: openF1Data[j].sessionKey
                };

                dispatch({type: 'add', payload: newEvent});
            }
        }
    }

    const compareDBToOpenF1 = (event, property) => {
        // match with open f1 (assumes max one event per type)
        const matchedSession = openF1Sessions.find((s) => s.type === event.type);

        // match original DB object
        const matchedDBEvent = dbData.find((e) => e.eventId === event.eventId);


        if(matchedSession === undefined) {
            return undefined;
        }

        if (property === 'Meeting Key') {
            if(matchedDBEvent?.meetingKey === matchedSession.meetingKey) {
                return undefined;
            } else {
                return matchedSession.meetingKey;
            }
        } else if (property === 'Session Key') {
            if(matchedDBEvent?.sessionKey === matchedSession.sessionKey) {
                return undefined;
            } else {
                return matchedSession.sessionKey;
            }
        } else if (property === 'Date') {
            if(matchedDBEvent?.date === matchedSession.date) {
                return undefined;
            } else {
                return matchedSession.date;
            }
        }
    }
    
    // const compareToOpenF1 = (event, sessionData, openF1Data) => {
    //     setLoader({show: true, text: 'Comparing data with Open F1...'});
    //     //Assumes there is only one of each event type
    //     console.log('compareToOpenF1', openF1Data);

    //     //Clear all previously matched data
    //     dispatch({type: 'clear_matched_data'});

    //     //Find matched but different data
    //     let nMatches = 0;
    //     let nDifferences = 0;
    //     let payload;
    //     for (let i = 0; i < sessionData.length; i++) {
    //         for (let j = 0; j < openF1Data.length; j++) {
    //             if(sessionData[i].type === openF1Data[j].type) {
    //                 console.log('Match', openF1Data[j]);
    //                 nMatches++;
    //                 if(sessionData[i].date !== openF1Data[j].date) {
    //                     nDifferences++;
    //                     payload = {
    //                         eventId: sessionData[i].id, 
    //                         matchedDate: {
    //                             current: sessionData[i].date, 
    //                             openF1: openF1Data[j].date, 
    //                             show: sessionData[i].date === null ? 'OpenF1' : 'Current'
    //                         }
    //                     };
    //                     dispatch({type: 'update_matched_date', payload: payload});
                        
    //                     if (sessionData[i].date === null) {
    //                         payload = {
    //                             eventId: sessionData[i].id, 
    //                             date: openF1Data[j].date,
    //                             datetime: new Date(openF1Data[j].date),
    //                             datetimestring: new Date(openF1Data[j].date).toISOString().slice(0, 16) // YYYY-MM-DDTHH:DD
    //                         }
    //                         dispatch({type: '', payload: payload});
    //                     }
    //                     console.log(`mismatch date: ${sessionData[i].id}`);
    //                 }
    //                 if(sessionData[i].meetingKey !== openF1Data[j].meetingKey) {
    //                     nDifferences++;
    //                     payload = {
    //                         eventId: sessionData[i].id, 
    //                         matchedMeetingKey: {
    //                             current: sessionData[i].meetingKey, 
    //                             openF1: openF1Data[j].meetingKey, 
    //                             show: sessionData[i].meetingKey === null ? 'OpenF1' : 'Current'
    //                         }
    //                     };
    //                     dispatch({type: 'update_matched_meeting_key', payload: payload});
                        
    //                     if (sessionData[i].meetingKey === null) {
    //                         dispatch({type: 'update_meeting_key', payload: {eventId: sessionData[i].id, meetingKey: openF1Data[j].meetingKey}});
    //                     }
    //                     console.log(`mismatch meetingKey: ${sessionData[i].id}`);
    //                 }
    //                 if(sessionData[i].sessionKey !== openF1Data[j].sessionKey) {
    //                     nDifferences++;
    //                     payload = {
    //                         eventId: sessionData[i].id, 
    //                         matchedSessionKey: {
    //                             current: sessionData[i].sessionKey, 
    //                             openF1: openF1Data[j].sessionKey, 
    //                             show: sessionData[i].sessionKey === null ? 'OpenF1' : 'Current'
    //                         }
    //                     };
    //                     dispatch({type: 'update_matched_session_key', payload: payload});
                        
    //                     if (sessionData[i].sessionKey === null) {
    //                         dispatch({type: 'update_session_key', payload: {eventId: sessionData[i].id, sessionKey: openF1Data[j].sessionKey}});
    //                     }
    //                     console.log(`mismatch sessionKey: ${sessionData[i].id}`);
    //                 }
    //             }
    //         }
    //     }

    //     //Add any missing from
    //     let matchExists = false;
    //     for (let j = 0; j < openF1Data.length; j++) {
    //         for (let i = 0; i < sessionData.length; i++) {
    //             if(sessionData[i].type === openF1Data[j].type) {
    //                 matchExists = true;
    //             }
    //         }

    //         //then this is a new event to be added
    //         if (matchExists === false) {
    //             // console.log('no match', openF1Data[j]);
                
    //             let payload = {
    //                 name: event.name,
    //                 type: openF1Data[j].type,
    //                 date: new Date(openF1Data[j].date).toISOString(),
    //                 circuitId: event.circuitId,
    //                 round: event.round,
    //                 season: event.season,
    //                 url: null,
    //                 posterUrl: null,
    //                 accentColor: null,
    //                 meetingKey: openF1Data[j].meetingKey,
    //                 sessionKey: openF1Data[j].sessionKey,
    //             };
    //             dispatch({type: 'add_event', payload: payload});
    //         } else {
    //             matchExists = false;
    //         }
    //     }

    //     console.log('compareToOpenF1: nMatches', nMatches);
    //     if(openF1Data.length===0) {
    //         setMatchMessage('No sessions found in OpenF1.');
    //     } else {
    //         if(nMatches===0) {
    //             setMatchMessage(`Found ${openF1Data.length-nMatches} new sessions, and ${nDifferences} session differences from OpenF1.`);

    //         } else if (nMatches===openF1Data.length) {
    //             setMatchMessage('All sessions matched with OpenF1.');
    //         }
    //     }
        
    //     setLoader({show: false});
    // }
    


    const save = async () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                events: events
            })
        };

        try {
            const response = await fetch(apiUrl + "/app/ref/events/save", requestOptions);
            if(response.status === 200) {
                fetchEvent();
                setHasSaved(true);
            }

        } catch (err) {
            console.log(err);
        }

    }

    const swapMatchedData = (index, event, property) => {
        // match with open f1
        const matchedSession = openF1Sessions.find((s) => s.type === event.type);

        // match original DB object
        let matchedDBEvent = dbData.find((e) => e.eventId === event.eventId);

        if(matchedDBEvent === undefined) {
            matchedDBEvent = {meetingKey: null, sessionKey: null, date: null};
        }


        let newValue;

        if (property === 'Meeting Key') {
            if(event.meetingKey === matchedSession.meetingKey) {

                console.log('event.meetingKey === matchedSession.meetingKey',event.meetingKey, matchedSession.meetingKey, matchedDBEvent.meetingKey);
                newValue = matchedDBEvent?.meetingKey;
            } else {
                newValue = matchedSession.meetingKey;
            }
        } else if (property === 'Session Key') {
            if(event.sessionKey === matchedSession.sessionKey) {
                newValue = matchedDBEvent?.sessionKey;
            } else {
                newValue = matchedSession.sessionKey;
            }
        } else if (property === 'Date') {
            if(event.date === matchedSession.date) {
                newValue = matchedDBEvent?.date;
            } else {
                newValue = matchedSession.date;
            }
        }

        dispatch({type: 'update', payload: {index: index, property: property, value: newValue}});
    }

    const isPropertyDirty = (index, property) => {
        if (property === 'Meeting Key') {
            return events[index].meetingKey !== dbData[index]?.meetingKey;

        } else if (property === 'Session Key') {
            return events[index].sessionKey !== dbData[index]?.sessionKey;

        } else if (property === 'Date') {
            return events[index].date !== dbData[index]?.date;
        }
    }



    const deleteEvent = (e, index) => {
        dispatch({type: 'delete', payload: {index: index}});
    }

    const restoreEvent = (e, index) => {
        dispatch({type: 'restore', payload: {index: index}});
    }

    const addManualEvent = (e) => {
        e.preventDefault();

        dispatch({type: 'add'});
    }

    const handlePropertyChange = (index, property, value) => {
        // let meetingKey = parseInt(e.target.value, 10);
        dispatch({type: 'update', payload: {index: index, property: property, value: value}});
    }
    
    const isDirty = () => {
        if (JSON.stringify(events) !== JSON.stringify(dbData)) { 
            return true; 
        } else {
            return false;
        }
    }

    const handleCloseEvent = () => {
        if(hasSaved) {
            onHide('Reload');
        } else {
            onHide();
        }
    }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
            <Loader config={loader}></Loader>

            <div className="flex justify-content-between">
                <h2>Schedule Settings</h2>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>

            <div><Button variant='link' onClick={fetchEvent}>Search OpenF1 again</Button></div>

            <div style={{overflowX: 'auto'}}>
                <table className='table table-layout-fixed dark-2 schedule-settings-table'>
                    <colgroup>
                        <col col-width='medium-text' />
                        <col col-width='medium-text' />
                        <col col-width='short-text' />
                        <col col-width='short-text' />
                        <col col-width='short-number' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Date (Local)</th>
                            <th>Meeting Key</th>
                            <th>Session Key</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                    { events !== undefined && events.length > 0 && (
                        events.map((event, index) => (
                        <tr className={event.action==='delete' ? 'delete-record' : (event.action==='add' ? 'new-record' : '')}>
                            <td>
                                <Select 
                                    options={eventTypes}
                                    value={event.type}
                                    clickFunction={(value) => handlePropertyChange(index, 'Date', value)}
                                    >
                                </Select>
                            </td>
                            <td>
                                <div className='flex'>
                                    <Input 
                                        style={isPropertyDirty(index, 'Date') ? {color: 'dodgerblue'} : {}} 
                                        type="datetime-local" 
                                        value={event.date || ''} 
                                        onChange={(e) => handlePropertyChange(index, e.target.name, e.target.value)} 
                                        name="Date"
                                        showLabel={false} />
                                    {compareDBToOpenF1(event, 'Date') !== undefined && (
                                        <Button variant="icon" style={{color: 'dodgerblue'}} onClick={(e) => swapMatchedData(index, event, 'Date')}><EmergencyIcon fontSize="small" /></Button>
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className='flex'>
                                    <Input 
                                        style={isPropertyDirty(index, 'Meeting Key') ? {color: 'dodgerblue'} : {}} 
                                        type="text" 
                                        value={event.meetingKey || ''} 
                                        onChange={(e) => handlePropertyChange(index, e.target.name, e.target.value)} 
                                        name="Meeting Key"
                                        showLabel={false} />
                                    {compareDBToOpenF1(event, 'Meeting Key') !== undefined && (
                                        <Button variant="icon" style={{color: 'dodgerblue'}} onClick={(e) => swapMatchedData(index, event, 'Meeting Key')}><EmergencyIcon fontSize="small" /></Button>
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className='flex'>
                                    <Input 
                                        style={isPropertyDirty(index, 'Session Key') ? {color: 'dodgerblue'} : {}} 
                                        type="text" 
                                        value={event.sessionKey || ''} 
                                        onChange={(e) => handlePropertyChange(index, e.target.name, e.target.value)} 
                                        name="Session Key"
                                        showLabel={false} />
                                    {compareDBToOpenF1(event, 'Session Key') !== undefined  && (
                                        <Button variant="icon" style={{color: 'dodgerblue'}} onClick={(e) => swapMatchedData(index, event, 'Session Key')}><EmergencyIcon fontSize="small" /></Button>
                                    )}
                                </div>
                            </td>
                            <td>
                                {event.type !== 'Grand Prix' && (<>
                                    {event.action !== 'delete' && (
                                    <Button variant="icon" onClick={(e) => deleteEvent(e, index)}><DeleteIcon fontSize='small' /></Button>
                                    )}
                                    {event.action === 'delete' && (
                                    <Button variant="icon" onClick={(e) => restoreEvent(e, index)}><ReplayIcon fontSize='small' /></Button>
                                    )}
                                </>)}
                            </td>
                        
                        </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
            
            <Button variant="outline" onClick={addManualEvent}>Add Event</Button>

            <div className="divider"></div>

            <div className="modal-footer">
                <Button variant="outline" onClick={handleCloseEvent}>Close</Button>
            </div>

        </Modal>

     );
    
}

export default ScheduleSettings;