import React, { useReducer, useState, useEffect } from 'react';

import Constructor from '../../../../components/Constructor/Constructor';

import './ConstructorPicks.css';


function ConstructorPicks({user, questionId, submissionId, eventId, submitTest, onPickChange}) {
	const [canSubmit, setCanSubmit] = useState(false);
	const [constructorPick, setConstructorPick] = useState();	
	const [selectable, setSelectable] = useState();	
	const [showSelectable, setShowSelectable] = useState(false);
	
	useEffect(() => {
		if(submissionId !== undefined) {
			fetchSubmissionPicks();
			fetchSelectableAnswers();
			
			let [canSub] = submitTest();
			setCanSubmit(canSub);
		}
	}, [questionId, submissionId]);

	
	const fetchSubmissionPicks = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				submissionId: submissionId
            })
        };
    
		fetch(apiUrl + `/app/submissionPicks`, requestOptions)
			.then(response => response.json())
			.then(data => {
				let pick = data[0];
				if(pick === undefined) {
					setConstructorPick({constructor: {}})
				} else {
					setConstructorPick(pick);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	
	const fetchSelectableAnswers = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				submissionId: submissionId
            })
        };
    
		fetch(apiUrl + `/app/form/selectableAnswers`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setSelectable(data.sort((a, b) => a.order - b.order));
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const toggleSelectable = () => {
		setShowSelectable(!showSelectable);
	}


	
    const handleUserAnswerChange = async (answer) => {
		console.log(`handleUserAnswerChange`, answer);
		
		// prior to setting pick, test again
		let [canSub, message] = submitTest();
		setCanSubmit(canSub);

		if(canSub || user.isAdmin) {
			let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					submissionId: submissionId, 
					questionId: questionId,
					answerId: answer.answerId,
					order: 0,
					replace: true
				})
			};

			try {
				const response = await fetch(apiUrl + "/app/submission/answer/set", requestOptions);
				const data = await response.json();
				if(data.length > 0) {
					setConstructorPick(answer);
					toggleSelectable();
					onPickChange(questionId);
				}
			} catch(err) {
				console.log(err)
			}
		} else {
			console.log(message);
		}
    }

    return (<>
		<div className='selection-container'>
			<Constructor constructor={typeof constructorPick?.constructor === 'object' ? constructorPick?.constructor : undefined} variant='input-toggle'
				answer={{...constructorPick, submissionId, questionId}}
				eventId={eventId}
				inputClick={toggleSelectable}
				canSubmit={canSubmit}
				isAdmin={user.isAdmin}></Constructor>

			{showSelectable === true && (
			<div className='dark-card selectable-answers'>
				{selectable?.filter(a => a.answerId !== constructorPick.answerId).map(a => (
				<Constructor constructor={a.constructor} variant='input-pick'
					answer={{...a, questionId,}}
					eventId={eventId}
					inputClick={handleUserAnswerChange}
					canSubmit={canSubmit}
					isAdmin={user.isAdmin}></Constructor>
				))}
			</div>
			)}
		</div>
    </>);
    
}

export default ConstructorPicks;