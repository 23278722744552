import './App.css';
import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';

import VerifyEmail from "./pages/Auth/VerifyEmail";

import CustomizeUserChip from './components/UserChip/CustomizeUserChip';
import Modal from './atoms/Modal/Modal';
import MenuIcon from '@mui/icons-material/Menu';

// Web Pages
import Header from "./components/Header/Header";
import Nav from "./components/Nav/Nav";

import Home from "./pages/Home";
import About from "./pages/About/About";
import Rules from "./pages/Rules/Rules";
import Leagues from "./pages/Leagues/Leagues";
import Leaderboard from "./pages/Leaderboard/Leaderboard";
import Settings from "./pages/Settings/Settings";
import Profile from "./pages/Profile/Profile";
import Event from "./pages/Event/Event";

function App() {
	const [showNav, setShowNav] = useState(false);
	const addedUID = useRef(null);

	const [user, setUser] = useState({
		userId: null,
		firstName: '',
		lastName: '',
		email: '',
		avatarUrl: '',
		isAuthorized: false
	});

	const [showVerifyEmail, setShowVerifyEmail] = useState(false);
	const [showCreateProfile, setShowCreateProfile] = useState(false);


	useEffect(()=>{     
		onAuthStateChanged(auth, (firebaseUser) => {
			console.log('Auth state changed');

			if (firebaseUser !== null) {        
				// User is authenticated in Firebase, see docs for a list of available properties
				// https://firebase.google.com/docs/reference/js/firebase.User

				if(addedUID.current !== firebaseUser.uid) { //protect if the component if re-rendered (e.g. in dev using strict mode)
					if (firebaseUser.emailVerified) {
						signInFlow(firebaseUser);
					} else {
						// if they use the Email/Password sign in method, user could be authenticated but not have their email verified. Sign up with Google is automatically verified.
						openVerifyEmail();
					}
				}
				addedUID.current = firebaseUser.uid;

			} else {
				// Clear user state
				setUser({
					userId: null,
					firstName: '',
					lastName: '',
					email: '',
					avatarUrl: '',
					isAuthorized: false
				})
				addedUID.current = null;
			}
		});
	}, [auth]);

	useEffect(() => {
		setShowNav(showNav);
	}, [ showNav ]);


	const openVerifyEmail = () => setShowVerifyEmail(true);
	const closeVerifyEmail = (action) => {
		setShowVerifyEmail(false);
	}


	const openCreateProfile = () => setShowCreateProfile(true);
	const closeCreateProfile = (action) => {
		setShowCreateProfile(false);
	}


  	const signInFlow = async (user) => {    
		try {
			let loggedInUser = await login(user);
			
			if(loggedInUser !== undefined) {
				console.log('Existing user logged in', loggedInUser);
				setUser({
					...loggedInUser,
					isAuthorized: true
				});

			} else {
				console.log('No account found.', addedUID.current);      

				let displayName;
				let avatarUrl;

				if (user.displayName===undefined || user.displayName===null || user.displayName==='') {
					displayName = user.email.split("@")[0];
				} else {
					displayName = user.displayName;
				}

				if (user.photoURL===undefined || user.photoURL===null || user.photoURL==='') {
					avatarUrl = '';
				} else {
					avatarUrl = user.photoURL
				}

				await addNewUser(user.uid, user.email, displayName, avatarUrl);
				
				loggedInUser = await login(user);
				console.log('New user logged in', loggedInUser);
				setUser({
					...loggedInUser,
					isAuthorized: true
				});
				
				openCreateProfile();
			}


		} catch (error) {
			console.error(error);
		}
	}

	const login = async (user) => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ 
				uid: user.uid,
				email: user.email
			})
		};

		const response = await fetch(apiUrl + "/user/login", requestOptions);
		try {
			let json = await response.json();

			if(json.length > 1) {
				throw new Error('Multiple accounts found.')
			}
			return json;
		} catch (error) {
			console.error('Error logging in', error);
			return undefined;
		}
	}

	const addNewUser = async (uid, email, displayName, avatarUrl) => {
		console.log('adding new user', displayName);
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				uid: uid,
				email: email,
				displayName: displayName,
				avatarUrl: avatarUrl
			})
		};
		const response = await fetch(apiUrl + "/user/add", requestOptions);
		const data = await response.json();
		console.log('added new user', data);

		return data;
	}
  
  return (
    <div className="App">

		<Modal show={showVerifyEmail} onHide={closeVerifyEmail} closeOnOverlayClick={true}>
			<VerifyEmail onClose={closeVerifyEmail}></VerifyEmail>
		</Modal>

		<Modal show={showCreateProfile} onHide={closeCreateProfile} closeOnOverlayClick={false}>
			<h2>Customize Profile</h2>
			<div className='dark-card note' style={{marginBottom: '1em'}}>
				<p>You can customize your profile later in the menu <MenuIcon style={{verticalAlign: 'middle', paddingBottom: '0.1em'}} fontSize='small' />.</p>
			</div>
			<CustomizeUserChip user={user} setUser={setUser} onClose={closeCreateProfile}></CustomizeUserChip>
		</Modal>


		<Router>
			<Header showNav={showNav} setShowNav={setShowNav} user={user}></Header>

			<Nav showNav={showNav} setShowNav={setShowNav} user={user}></Nav>
			
			<div className="main-content">
				<Routes>
					<Route exact path="/" element={<Home user={user} />} />
					<Route exact path="/settings" element={<Settings user={user} />} />
					<Route exact path="/profile" element={<Profile user={user} setUser={setUser} />} />
					<Route exact path="/leagues" element={<Leagues user={user} />} />
					<Route exact path="/leaderboard" element={<Leaderboard user={user} />} />
					<Route exact path="/event/" element={<Event user={user} />} />
					<Route exact path="/rules" element={<Rules />} />
					<Route exact path="/about" element={<About />} />
					<Route exact path="/complete-verification" element={<Home user={user}  />} />
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</div>
			
		</Router>
    </div>
  );
}

export default App;
