import React, { useState, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import Skeleton from '../Skeleton/Skeleton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Chip from '../../atoms/Chip/Chip';
import './ConstructorStats.css';

const ConstructorStats = ({config, onHide}) => {
    const [constructor, setConstructor] = useState({});
    const [stats, setStats] = useState([]);
    
    useEffect(() => {
        if(config.show) {
            console.log('config', config);
            fetchConstructor();
            fetchStats();
        }
    }, [config]);

    const fetchConstructor = async () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        
		const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                constructorId: config.constructorId
            })
        };

		try {
			const response = await fetch(apiUrl + `/app/ref/eventConstructor`, requestOptions);
            const data = await response.json();
			setConstructor(data);

        } catch (err) {
            console.log(err);
        }
    }

    const fetchStats = async () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                constructorId: config.constructorId
            })
        };

		try {
			const response = await fetch(apiUrl + `/app/ref/constructor/stats`, requestOptions);
            const data = await response.json();
			setStats(data);

        } catch (err) {
            console.log(err);
        }
    }

    return (
    <Modal show={config.show} onHide={onHide} closeOnOverlayClick={true} className='gap-1'>

		{Object.keys(constructor).length === 0 ? (<Skeleton variant='driver-card' className='full-width' />) : ( 
        <div className='dark-card full-width'>
            <div className='summary-container'>
                <div>
                    <img className='pill medium' src={constructor.logoUrl} style={{backgroundColor: `${constructor.accentColor}`, padding: '2px'}} alt={constructor.name} />
                </div>
                <div>
                    <div className='flex align-items-center gap-1'>
                        <h2 className='single-line'>{constructor.name}</h2>
                    </div>
                    <div className='flex align-items-center gap-1'>
                        <img className='flag' src={constructor.flagUrl} alt={constructor.nationality} />
                    </div>
                    {/* <div className='flex align-items-center gap-05'>
                        <img className='pill tiny' style={{backgroundColor: `${driver.constructor?.accentColor}`, padding: '2px'}} src={driver.constructor?.logoUrl} alt={driver.constructor?.name} />
                        <p>{driver.constructor?.name}</p>
                    </div> */}
                </div>
            </div>
        </div>
        )}
			
        <div className='table-container'>
            <table className='table table-layout-fixed'>
                <colgroup>
                    <col col-width='icon' />
                    <col col-width='icon' />
                    <col col-width='max-content' />
                    <col col-width='short-number' />
                </colgroup>
                <thead>
                    <th></th>
                    <th></th>
                    <th>Race</th>
                    <th>Place</th>
                </thead>
                <tbody>
                    {/* {stats.map((stat, index) => (
                        <tr>
                            <td>R{stat.round}</td>
                            <td><img className="flag small" alt={stat.locality} src={stat.flagUrl} /></td>
                            <td><div className='single-line'>{stat.name}</div></td>
                            <td>{formatPlace(stat.startPosition, stat.endPosition, stat.status)}</td>
                        </tr>
                    ))} */}
                </tbody>
            </table>
        </div>
    </Modal>

    );
}

export default ConstructorStats;
