import React, { useState, useEffect, useReducer } from 'react';

import Loader from '../../../../components/Loader/Loader';
import PictureChooser from '../../../../components/PictureChooser/PictureChooser';
import Constructor from '../../../../components/Constructor/Constructor';

import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';
import Input from '../../../../atoms/Input/Input';
import Select from '../../../../atoms/Select/Select';

import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import './EventConstructorSettings.css';

function constructorReducer(state, action) {
    console.log(`constructorReducer (${action.type}, ${action?.payload?.index})`);   
    
    let newState = structuredClone(state);
    try {

        let i = action?.payload?.index;

        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'delete':
                if(newState[i].action === 'add') {
                    newState.splice(i, 1);
                } else {
                    newState[i].action = 'delete';
                }
                return newState;

            case 'restore':
                delete newState[i].action;
                return newState;

            case 'update':
                if(action.payload.property === 'Logo Url') {
                    newState[i].logoUrl = action.payload.value;
                    
                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}

function pitStopReducer(state, action) {
    console.log(`pitStopReducer (${action.type}, ${action?.payload?.index})`);   
    
    let newState = structuredClone(state);
    try {

        let i = action?.payload?.index;

        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'delete':
                if(newState[i].action === 'add') {
                    newState.splice(i, 1);
                } else {
                    newState[i].action = 'delete';
                }
                return newState;

            case 'restore':
                delete newState[i].action;
                return newState;

            case 'add':
                newState.push({
                    action: 'add',
                    driver: null,
                    lap: null,
                    duration: null
                });
                return newState;

            case 'update':
                if(action.payload.property === 'Lap') {
                    newState[i].lap = action.payload.value;
                    
                } else if(action.payload.property === 'Duration') {
                    newState[i].duration = action.payload.value;
                    
                } else if(action.payload.property === 'Driver') {
                    newState[i].driverId = action.payload.value.driverId;
                    newState[i].driverNumber = action.payload.value.driverNumber;
                    newState[i].firstName = action.payload.value.firstName;
                    newState[i].lastName = action.payload.value.lastName;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}




function EventConstructorSettings({config, onHide}) {
    const [constructor, dispatchConstructor] = useReducer(constructorReducer, []);
    const [dbConstructorData, setDbConstructorData] = useState();

    const [pitStop, dispatchPitStop] = useReducer(pitStopReducer, []);
    const [dbPitStopData, setDbPitStopData] = useState();

    const [availableDrivers, setAvailableDrivers] = useState([]);

    const [loader, setLoader] = useState({show: false, text: ''});
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});

    const [hasSaved, setHasSaved] = useState(false);
    
    useEffect(() => {
        if(config.show) {
            fetchConstructors(config.eventId);
            fetchPitStops(config.eventId);
            fetchAvailableDrivers();
            
            console.log('config', config);
        }

    }, [config]);

    


    const fetchConstructors = async () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId
            })
        };
        
        setLoader({show: true, text: 'Loading Event Drivers...'});
        return await fetch(apiUrl + `/app/ref/eventConstructors`, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatchConstructor({type: 'initialize', payload: data});
                setDbConstructorData(data);
                setLoader({show: false});
                return data;
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
            });
    }

    const fetchPitStops = async () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId
            })
        };
        
        setLoader({show: true, text: 'Loading Pit Stops...'});
        return await fetch(apiUrl + `/app/ref/eventPitStops`, requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatchPitStop({type: 'initialize', payload: data});
                setDbPitStopData(data);
                setLoader({show: false});
                return data;
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
            });
    }
    

    const fetchAvailableDrivers = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            eventId: config.eventId
          })
      };

      fetch(apiUrl + `/app/ref/eventDrivers`, requestOptions)
        .then(response => response.json())
        .then(data => {
            const ordered = data.sort((a, b) => a.lastName.localeCompare(b.lastName));
            setAvailableDrivers(ordered);
            
        })
        .catch((err) => {
            console.log(err);            
        });
    }
    
    
    const saveConstructors = () => {
        // console.log('saveForm', state.form);
        setLoader({show: true, text: 'Saving Constructors...'});

        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                constructor: constructor
            })
        };

        fetch(apiUrl + "/app/ref/saveEventConstructors", requestOptions)
          .then(response => {
            if(response.status === 200) {
                fetchConstructors();
                setHasSaved(true);
            }
            setLoader({show: false});
          })
        .catch((err) => {
            console.log(err);
        });
    }    
    
    const savePitStops = () => {
        // console.log('saveForm', state.form);
        setLoader({show: true, text: 'Saving Pit Stops...'});
        const filteredPitStops = pitStop.filter(p => p.driverId !== null && p.lap !== null);

        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                pitStop: filteredPitStops
            })
        };

        fetch(apiUrl + "/app/ref/saveEventPitStops", requestOptions)
          .then(response => {
            if(response.status === 200) {
                fetchPitStops();
                setHasSaved(true);
            }
            setLoader({show: false});
          })
        .catch((err) => {
            console.log(err);
        });
    }

    const handleCloseEvent = () => {
        if(hasSaved) {
            onHide('Reload');
        } else {
            onHide();
        }
        setHasSaved(false);
    }

    
    const formatDuration = (duration) => {        
        let minutes = Math.floor(duration / 60);
        let seconds = (duration % 60).toFixed(3); // Keep the thousandth place

        if (minutes === 0) {
            return `${seconds}`;

        } else {
            let [real, decimal] = seconds.split('.');
            real = real.padStart(2, '0'); // Ensure the real part is two digits
            seconds = `${real}.${decimal}`;
            return `${minutes}:${seconds}`;
        }
    }

    const openPictureChooser = (index) => {
        setPictureChooserConfig({show: true, index: index});
    }

    const closePictureChooser = (url) => {
        console.log(`url (${url.length})`, url);

        if(url.length > 0) {
            let index = pictureChooserConfig.index;
            updateConstructorProperty('Logo Url', url, index);
        }

        setPictureChooserConfig({show: false});
    }
    
    const deleteConstructor = (_, i) => {
        dispatchConstructor({type: 'delete', payload: {index: i}});
    }
    const restoreConstructor = (_, i) => {
        dispatchConstructor({type: 'restore', payload: {index: i}});
    }
    const updateConstructorProperty = (property, value, i) => {
        dispatchConstructor({type: 'update', payload: {index: i, property: property, value: value}});
    }
    
    const addPitStop = (_, i) => {
        dispatchPitStop({type: 'add', payload: {index: i}});
    }    
    const deletePitStop = (_, i) => {
        dispatchPitStop({type: 'delete', payload: {index: i}});
    }
    const restorePitStop = (_, i) => {
        dispatchPitStop({type: 'restore', payload: {index: i}});
    }
    const updatePitStopProperty = (property, value, i) => {
        dispatchPitStop({type: 'update', payload: {index: i, property: property, value: value}});
    }


    const isConstructorsDirty = () => {
        return JSON.stringify(constructor) !== JSON.stringify(dbConstructorData);
    }

    const isPitStopsDirty = () => {
        return JSON.stringify(pitStop) !== JSON.stringify(dbPitStopData);
    }

    
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
            <Loader config={loader}></Loader>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>

            <div className="flex justify-content-between">
                <h2>Event Constructor Settings</h2>
                <Button variant="primary" onClick={saveConstructors} disabled={!isConstructorsDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>
            
            <div className="label-adorner">
            {`{ eventId: ${config.eventId} }`}
            </div>

            <div className="table-container flex-shrink-0">
                <table className='table'>
                    <tr>
                        <th>Constructor</th>
                        <th>Logo</th>
                        <th></th>
                    </tr>

                    { constructor !== undefined && constructor.length > 0 && (
                    constructor.map((c, index) => (
                    <tr>
                        <td>
                            <Constructor constructor={c}></Constructor>
                        </td>

                        <td>
                            <Button variant="icon" onClick={() => openPictureChooser(index)}>
                                <img className='constructor-logo-inline' src={c.logoUrl} alt={c.name}></img>
                            </Button>
                        </td>

                        <td>
                            {c.action !== 'delete' && (
                            <Button variant="icon" onClick={(e) => deleteConstructor(e, index)}><DeleteIcon fontSize='small' /></Button>
                            )}
                            {c.action === 'delete' && (
                            <Button variant="icon" onClick={(e) => restoreConstructor(e, index)}><ReplayIcon fontSize='small' /></Button>
                            )}
                        </td>                    
                    </tr>
                    ))
                )}
                </table>
            </div>

            
            <div className='flex justify-content-between'>
                <h3>Pit Stops</h3>
                <Button variant="primary" onClick={savePitStops} disabled={!isPitStopsDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>
            <div className="table-container flex-shrink-0">
                <table className='table table-layout-fixed'>
                    <colgroup>
                        <col col-width='medium-text' />
                        <col col-width='short-number' />
                        <col col-width='short-text' />
                        <col col-width='2em' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Driver</th>
                            <th>Lap</th>
                            <th>Duration</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                    { pitStop !== undefined && pitStop.length > 0 && (
                        pitStop.map((p, index) => (
                        <tr>
                            <td>
                                <Select 
                                    options={availableDrivers.map((d) => `${d.firstName} ${d.lastName}`)}
                                    value={`${p.firstName} ${p.lastName}`}
                                    allowBlank={true}
                                    clickFunction={(_, i) => updatePitStopProperty('Driver', availableDrivers[i], index)} 
                                    >
                                </Select>
                            </td>

                            <td>
                                <Input 
                                    type="text" // text instead of number to not have the number change buttons
                                    name="Lap" 
                                    value={p.lap}
                                    showLabel={false}
                                    onChange={(e) => updatePitStopProperty(e.target.name, e.target.value, index)} />
                            </td>
                            
                            <td>
                                <Input type="text"  // text instead of number to not have the number change buttons
                                    name="Duration" 
                                    value={p.duration}
                                    showLabel={false}
                                    onChange={(e) => updatePitStopProperty(e.target.name, e.target.value, index)} />
                            </td>

                            <td>
                                {p.action !== 'delete' && (
                                <Button variant="icon" onClick={(e) => deletePitStop(e, index)}><DeleteIcon fontSize='small' /></Button>
                                )}
                                {p.action === 'delete' && (
                                <Button variant="icon" onClick={(e) => restorePitStop(e, index)}><ReplayIcon fontSize='small' /></Button>
                                )}
                            </td>
                        </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>

            <Button variant="outline" onClick={addPitStop}>Add Pit Stop</Button>

            <div className="modal-footer">
                <Button variant="secondary" onClick={handleCloseEvent}>Close</Button>
            </div>

        </Modal>

     );
    
}

export default EventConstructorSettings;