import React, { useState, useEffect } from 'react';

import Driver from '../../../../components/Driver/Driver';


import './DriverPicks.css';


function DriverPicks({user, questionId, submissionId, eventId, submitTest, onPickChange}) {
	const [canSubmit, setCanSubmit] = useState(false);
	const [groupAPick, setGroupAPick] = useState();
	const [groupBPick, setGroupBPick] = useState();
	const [groupCPick, setGroupCPick] = useState();
	
	const [groupASelectable, setGroupASelectable] = useState();
	const [groupBSelectable, setGroupBSelectable] = useState();
	const [groupCSelectable, setGroupCSelectable] = useState();
	
	const [showGroupASelectable, setShowGroupASelectable] = useState(false);
	const [showGroupBSelectable, setShowGroupBSelectable] = useState(false);
	const [showGroupCSelectable, setShowGroupCSelectable] = useState(false);
	
	useEffect(() => {
		if(submissionId !== undefined) {
			fetchSubmissionPicks();
			fetchSelectableAnswers();
			
			let [canSub] = submitTest();
			setCanSubmit(canSub);
		}

	}, [questionId, submissionId]);

	
	const fetchSubmissionPicks = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				submissionId: submissionId
            })
        };
    
		fetch(apiUrl + `/app/submissionPicks`, requestOptions)
			.then(response => response.json())
			.then(data => {
				let pickA = data.filter(pick => pick.order === 1)[0];
				if(pickA === undefined) {
					console.log('pickA undefined');
					setGroupAPick({driver: {}})
				} else {
					console.log('pickA--', pickA);
					setGroupAPick(pickA);
				}
				let pickB = data.filter(pick => pick.order === 2)[0];
				if(pickB === undefined) {
					setGroupBPick({driver: {}})
				} else {
					setGroupBPick(pickB);
				}
				let pickC = data.filter(pick => pick.order === 3)[0];
				if(pickC === undefined) {
					setGroupCPick({driver: {}})
				} else {
					setGroupCPick(pickC);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	
	const fetchSelectableAnswers = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				submissionId: submissionId
            })
        };
    
		fetch(apiUrl + `/app/form/selectableAnswers`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setGroupASelectable(data.filter(answer => answer.order <= 5).sort((a, b) => a.order - b.order));
				setGroupBSelectable(data.filter(answer => answer.order > 5 && answer.order <=10).sort((a, b) => a.order - b.order));
				setGroupCSelectable(data.filter(answer => answer.order > 10).sort((a, b) => a.order - b.order));
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const toggleSelectable = (_, group) => {		
		console.log(!showGroupASelectable);
		if(group === 'A') {
			setShowGroupASelectable(!showGroupASelectable);
		} else if(group === 'B') {
			setShowGroupBSelectable(!showGroupBSelectable);
		} else if(group === 'C') {
			setShowGroupCSelectable(!showGroupCSelectable);
		}
	}


	
    const handleUserAnswerChange = async (answer, group) => {
		console.log(`handleUserAnswerChange (${group})`, answer);
		
		// prior to setting pick, test again
		let [canSub, message] = submitTest();
		setCanSubmit(canSub);

		if(canSub || user.isAdmin) {
			let order;
			if(group === 'A') {
				order = 1;
			} else if(group === 'B') {
				order = 2;
			} else if(group === 'C') {
				order = 3;
			}
	
			let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					submissionId: submissionId, 
					questionId: questionId,
					answerId: answer.answerId,
					order: order,
					replace: true
				})
			};
	
			try {
				const response = await fetch(apiUrl + "/app/submission/answer/set", requestOptions);
				const data = await response.json();
				if(data.length > 0) {
					if(group === 'A') {
						setGroupAPick(answer);
					} else if(group === 'B') {
						setGroupBPick(answer);
					} else if(group === 'C') {
						setGroupCPick(answer);
					}
					toggleSelectable(answer, group);
					onPickChange(questionId);
				}
			} catch(err) {
				console.log(err)
			}
		} else {
			console.log(message);
		}
    }

    return (
		<div>
			<div className='flex justify-content-between'>
				<h3>Group A</h3>
			</div>
			<div className='selection-container'>
				<Driver driver={groupAPick?.driver} variant='input-toggle'
					answer={{...groupAPick, submissionId, questionId}}
					eventId={eventId}
					group='A'
					inputClick={toggleSelectable}
					canSubmit={canSubmit}
					isAdmin={user.isAdmin}></Driver>

				{showGroupASelectable === true && (
				<div className='dark-card selectable-answers'>
					{groupASelectable?.filter(a => a.answerId !== groupAPick.answerId).map(a => (
					<Driver driver={a.driver} variant='input-pick'
						answer={{...a, questionId}}
						eventId={eventId}
						group='A'
						inputClick={handleUserAnswerChange}
						canSubmit={canSubmit}
						isAdmin={user.isAdmin}></Driver>
					))}
				</div>
				)}
			</div>
			
			<div className='flex justify-content-between'>
				<h3>Group B</h3>
			</div>
			<div className='selection-container'>
				<Driver driver={groupBPick?.driver} variant='input-toggle'
					answer={{...groupBPick, submissionId, questionId}}
					eventId={eventId}
					group='B'
					inputClick={toggleSelectable}
					canSubmit={canSubmit}
					isAdmin={user.isAdmin}></Driver>

				{showGroupBSelectable === true && (
				<div className='dark-card selectable-answers'>
					{groupBSelectable?.filter(a => a.answerId !== groupBPick.answerId).map(a => (
					<Driver driver={a.driver} variant='input-pick'
						answer={{...a, questionId}}
						eventId={eventId}
						group='B'
						inputClick={handleUserAnswerChange}
						canSubmit={canSubmit}
						isAdmin={user.isAdmin}></Driver>
					))}
				</div>
				)}
			</div>
		
			<div className='flex justify-content-between'>
				<h3>Group C</h3>
			</div>
			<div className='selection-container'>
				<Driver driver={groupCPick?.driver} variant='input-toggle'
					answer={{...groupCPick, submissionId, questionId}}
					eventId={eventId}
					group='C'
					inputClick={toggleSelectable}
					canSubmit={canSubmit}
					isAdmin={user.isAdmin}></Driver>

				{showGroupCSelectable === true && (
				<div className='dark-card selectable-answers'>
					{groupCSelectable?.filter(a => a.answerId !== groupCPick.answerId).map(a => (
					<Driver driver={a.driver} variant='input-pick'
						answer={{...a, questionId}}
						eventId={eventId}
						group='C'
						inputClick={handleUserAnswerChange}
						canSubmit={canSubmit}
						isAdmin={user.isAdmin}></Driver>
					))}
				</div>
				)}
			</div>
		</div>
	);
    
}

export default DriverPicks;