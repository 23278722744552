import React from 'react';
import { signInWithGoogle } from '../../firebase';
import Button from '../../atoms/Button/Button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import './Auth.css';
 
const SignUp = ({onClose}) => {

    const handleOpenSignIn = () => {onClose('Open Sign In');}

    const handleSignUpWithGoogle = async () => {
        await signInWithGoogle();
        onClose();
    }

    const handleSignUpWithEmail = () => {onClose('Open Email Sign Up');}
 
  return (
    <div className='auth-popup-container'>
        <h2 className='text-align-center'>Sign Up</h2>

        <p className='text-align-center'>
            Already have an account? <Button variant="link" onClick={handleOpenSignIn}>Sign in</Button>
        </p>

        <Button variant="auth" onClick={handleSignUpWithGoogle}>
            <img src='https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg' alt='Google' />
            Sign up with Google
        </Button>

        <Button variant="auth" onClick={handleSignUpWithEmail}>
            <MailOutlineIcon />
            Sign up with Email
        </Button>
        
        <Button variant="outline" onClick={onClose}>Close</Button>

    </div>
  )
}
 
export default SignUp