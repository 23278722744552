// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,  
  signOut
} from "firebase/auth";

//https://blog.logrocket.com/user-authentication-firebase-react-apps/

const firebaseConfig = {
    apiKey: "AIzaSyC4Wv8n_HUrlbwksTTqpSpUig37iyfI3xs",  
    authDomain: "formula-fantasy-114c0.firebaseapp.com",  
    projectId: "formula-fantasy-114c0",  
    storageBucket: "formula-fantasy-114c0.appspot.com",  
    messagingSenderId: "777754667966",  
    appId: "1:777754667966:web:c938742609a8a3bc0da7ef",  
    measurementId: "G-VS4VKM6Q70"  
  };
  
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);




// Email & Password
const sendVerificationEmail = async (auth) => {
  try {
    let appUrl = process.env.REACT_APP_FORMULA_FANTASY_REACT_APP;
    
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `${appUrl}/complete-verification`
    };
    
    console.log(`Verification email sent rith redirect URL:${appUrl}/complete-verification`);

    await sendEmailVerification(auth.currentUser, actionCodeSettings);
    
    
  } catch (err) {throw err;}
}
const registerWithEmailAndPassword = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);

  } catch (err) {throw err;}
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);

  } catch (err) {throw err;}
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    
  } catch (err) {throw err;}
};



// Google
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    console.log(`signInWithGoogle`);
    await signInWithPopup(auth, googleProvider);

  } catch (error) {
	if (error.code === "auth/user-disabled") {
		console.error("This user account has been disabled.");
		alert("Your account has been disabled. Please contact support.");
	} else {
		console.error("Error signing in:", error.message);
	}
  }
};



const logout = () => {
  signOut(auth);
};

export {
  auth,
  registerWithEmailAndPassword,
  sendVerificationEmail,
  logInWithEmailAndPassword,
  signInWithGoogle,
  sendPasswordReset,
  logout
}