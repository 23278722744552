import React from 'react';

import './UserChip.css';

function UserChip({user, variant, className}) {

    return (<>
        <div className={`user-chip ${className}`} variant={variant} style={{backgroundColor: user.color}}>
            <img className="avatar" src={user.avatarUrl==='' ? 'https://wus1saformulafantasy.blob.core.windows.net/formulafantasy-artifacts/TheStig.jpg' : user.avatarUrl} alt="?" />
            
            {(variant === undefined || (variant !== undefined && !variant.includes('avatar'))) && (<>
                <div className='text-area'>
                    <div className='display-name'>{user.displayName}</div>
                    {user.title!==undefined && (<div className='title'>{user.title}</div>)}
                </div>
            </>)}
        </div>
    </>)
}


export default UserChip