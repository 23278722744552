import React, { useReducer, useState, useEffect } from 'react';
import Loader from '../../../../components/Loader/Loader';
import PictureChooser from '../../../../components/PictureChooser/PictureChooser';
import AreYouSureModal from '../../../../components/AreYouSureModal/AreYouSureModal';
import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';
import Input from '../../../../atoms/Input/Input';
import Select from '../../../../atoms/Select/Select';

import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import './ConstructorSettings.css';

function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload})`);   
    
    let newState = structuredClone(state);

    try {  
        switch(action.type) {
            case 'initialize':
                newState = action.payload;
                return newState;
  
            case 'reset':
                newState = {};
                return newState;
  
            case 'add':
                newState = {
                    action: 'add',
                    ...action.payload
                };
                return newState;

            case 'update':
                if(action.payload.property === 'Name') {
                    newState.name = action.payload.value;
                    
                } else if(action.payload.property === 'Nationality') {
                    newState.nationality = action.payload.value;
                    newState.flagUrl = '';

                } else if(action.payload.property === 'Logo URL') {
                    newState.logoUrl = action.payload.value;
                    
                } else if(action.payload.property === 'Accent Color') {
                    newState.accentColor = action.payload.value;
                    
                } else if(action.payload.property === 'Car Pic URL') {
                    newState.carPicUrl = action.payload.value;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}



function ConstructorSettings({config, onHide}) {
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});
    const [deleteModalConfig, setDeleteModalConfig] = useState({show: false});
    const [constructor, dispatch] = useReducer(reducer, []);
    const [dbData, setDbData] = useState();
    const [hasSaved, setHasSaved] = useState(false);
    const [countries, setCountries] = useState([]);
    const [loader, setLoader] = useState({show: false, text: ''});
    
    useEffect(() => {
        if(config.show) {
			console.log(config);
            fetchConstructor(config.constructorId);
			fetchCountries();
        }

    }, [config]);
    

    const fetchConstructor = (constructorId) => {    
		dispatch({type: 'initialize', });   //set undefined while loading

        if(constructorId === undefined) {
            dispatch({type: 'add', payload: {active: config.active}});

        } else {
            let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    constructorId: constructorId
                })
            };
        
            setLoader({show: true, text: 'Loading Driver...'});
            fetch(apiUrl + `/app/ref/constructor`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setDbData(data);
                    dispatch({type: 'initialize', payload: data});
                    setLoader({show: false});
                })
                .catch((err) => {
                    console.log(err);
                    setLoader({show: false});
                });

        }
    }
        

    const fetchCountries = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        fetch(apiUrl + `/app/ref/countries`, requestOptions)
            .then(response => response.json())
            .then(data => {
				data = data.filter(c => c.nationality !== null && c.nationality.length > 0)
                const ordered = data.sort((a, b) => a.nationality.localeCompare(b.nationality));
                setCountries(ordered);
            })
            .catch((err) => {
                console.log(err);
            });
    }


	const save = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
	
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				constructors: [constructor]
			})
		};

		fetch(apiUrl + "/app/ref/saveConstructors", requestOptions)
			.then(response => {
				if(response.status === 200) {
                    handleCloseEvent('Reload');
                    setHasSaved(true);
                }
			})
			.catch((err) => {
				console.log(err);
			});
	}
    

    const handlePropertyChange = (property, value) => {
        console.log(`handlePropertyChange [${property}] => ${value}`);

        dispatch({type: 'update', payload: {property: property, value: value}});
    }

    const openPictureChooser = (e, property) => {
        e.preventDefault();
        setPictureChooserConfig({show: true, property: property});
    }

    const closePictureChooser = (url) => {
        console.log(`${pictureChooserConfig.property} (${url.length})`, url);
        
        if(url.length > 0) {
            handlePropertyChange(pictureChooserConfig.property, url);
        }
        
        setPictureChooserConfig({show: false});
    }

    const isDirty = () => {
        return JSON.stringify(constructor) !== JSON.stringify(dbData);
    }

    const handleCloseEvent = (action) => {
        if(hasSaved) {action = 'Reload';}

        onHide(action);
        setHasSaved(false);
    }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true} className='gap-1'>
            <Loader config={loader}></Loader>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>
            {/* <AreYouSureModal config={deleteModalConfig} onHide={closeDeleteModal}></AreYouSureModal> */}


            <div className="flex justify-content-between">
                <h2>Constructor Settings</h2>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>
            
            {constructor !== undefined && (<>
                <div className="dark-card note">
                {`{ constructorId: ${constructor.constructorId} }`}
                </div>
                
				<div className='col-2'>
                    <Button variant="icon" onClick={(e) => openPictureChooser(e, 'Logo URL')}>
                        <img className="answer-image small" src={constructor.logoUrl} alt="Logo" />
                    </Button>

                    <Button variant="icon" onClick={(e) => openPictureChooser(e, 'Car Pic URL')}>
                        <img className="answer-image small" src={constructor.carPicUrl} alt="Car Pic" />
                    </Button>
                </div>

                <Input name="Name" 
                    type="text" 
                    value={constructor.name}
                    onChange={(e) => handlePropertyChange(e.target.name, e.target.value)} />
							
                    
				<div className='col-2'>
					<Select 
						name="Nationality" 
						options={countries.map(c => c.nationality)}
						value={constructor.nationality}
						clickFunction={(value) => handlePropertyChange('Nationality', value)} />

					<div className='dark-card note'>
						<img className='flag' src={constructor.flagUrl} alt={constructor.nationality} />
					</div>
				</div>
                    
                <Input name="Accent Color"
                    type="color" 
                    value={constructor.accentColor}
                    onChange={(e) => handlePropertyChange(e.target.name, e.target.value)} />
            </>)}

            <div className="modal-footer">
                <Button variant="secondary" onClick={handleCloseEvent}>Close</Button>
            </div>

        </Modal>

     );
    
}

export default ConstructorSettings;