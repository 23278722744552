import React, { useReducer, useState, useEffect } from 'react';

import Loader from '../../../../components/Loader/Loader';
import UserChip from '../../../../components/UserChip/UserChip';
import Answer from '../../../../components/Answer/Answer';

import Select from '../../../../atoms/Select/Select';
import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import './BonusRadioPicks.css';


function BonusRadioPicks({user, questionId, submissionId, submitTest, onPickChange}) {
	const [canSubmit, setCanSubmit] = useState(false);
	const [pick, setPick] = useState();	
	const [selectable, setSelectable] = useState();	
	const [showSelectable, setShowSelectable] = useState(false);
	
	useEffect(() => {
		if(submissionId !== undefined) {
			fetchSubmissionPicks();
			fetchSelectableAnswers();
			
			let [canSub] = submitTest();
			setCanSubmit(canSub);
		}
	}, [questionId, submissionId]);

	
	const fetchSubmissionPicks = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				submissionId: submissionId
            })
        };
    
		fetch(apiUrl + `/app/submissionPicks`, requestOptions)
			.then(response => response.json())
			.then(data => {
				let userPick = data[0];
				if(userPick === undefined) {
					setPick({})
				} else {
					setPick(userPick);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	
	const fetchSelectableAnswers = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				submissionId: submissionId
            })
        };
    
		fetch(apiUrl + `/app/form/selectableAnswers`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setSelectable(data.sort((a, b) => a.order - b.order));
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const toggleSelectable = () => {
		setShowSelectable(!showSelectable);
	}


    const handleUserAnswerChange = async (answer) => {
		console.log(`handleUserAnswerChange`, answer);
		
		// prior to setting pick, test again
		let [canSub, message] = submitTest();
		setCanSubmit(canSub);

		if(canSub || user.isAdmin) {
			let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					submissionId: submissionId, 
					questionId: questionId,
					answerId: answer.answerId,
					order: 0,
					replace: true
				})
			};

			try {
				const response = await fetch(apiUrl + "/app/submission/answer/set", requestOptions);
				const data = await response.json();
				if(data.length > 0) {
					setPick(answer);
					toggleSelectable();
					onPickChange(questionId);
				}
			} catch(err) {
				console.log(err)
			}
		} else {
			console.log(message);
		}
    }

    return (<>
		<div className='selection-container'>
			<Answer answer={{...pick, questionId, submissionId}} variant='input-toggle'
				inputClick={toggleSelectable}
				canSubmit={canSubmit}
				isAdmin={user.isAdmin}></Answer>
			
			{showSelectable === true && (
			<div className='dark-card selectable-answers'>
				{selectable?.filter(a => a.answerId !== pick.answerId).map(answer => (
				<Answer answer={{...answer, questionId}} variant='input-pick'
					inputClick={handleUserAnswerChange}
					canSubmit={canSubmit}
					isAdmin={user.isAdmin}></Answer>
				))}
			</div>
			)}
		</div>
    </>);
    
}

export default BonusRadioPicks;