import React, { useState, useEffect } from 'react';

import Button from '../../atoms/Button/Button';
import Chip from '../../atoms/Chip/Chip';

import UserChip from '../UserChip/UserChip';
import SkeletonAnswer from '../Skeleton/SkeletonAnswer';
import PointsBreakdown from '../PointsBreakdown/PointsBreakdown';

import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import './Answer.css';

/**
 * answer===undefined : skeleton state
 * answer==={} : empty state
 * answer===answer : answer state
 */
const Answer = ({ answer, variant, inputClick, canSubmit, isAdmin, responsesClick, submissions }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [pointsBreakdownConfig, setPointsBreakdownConfig] = useState({show: false});

    useEffect(() => {
        if(variant==='input-toggle') {
            setIsExpanded(false);
        }
    }, [answer]);

    const handleInputClick = () => {
        inputClick(answer);
        if(variant === 'input-toggle') {
            console.log('isExpanded', !isExpanded);
            setIsExpanded(!isExpanded);
        }
    }

    const openPointsBreakdown = () => {
        setPointsBreakdownConfig({
            show: true,
            submissionId: answer.submissionId,
            questionId: answer.questionId,
            answerId: answer.answerId,
        })
    }
    const hidePointsBreakdown = () => {
        setPointsBreakdownConfig({show: false})
    }
      
  return (<>
        <PointsBreakdown config={pointsBreakdownConfig} onHide={hidePointsBreakdown}></PointsBreakdown>

        {answer===undefined ? (
        <SkeletonAnswer input={true}></SkeletonAnswer>
        ) : (    
        <div variant={variant} className={`answer-container 
            ${variant === undefined ? '' : 'dark-card'}
            ${answer?.imageUrl !== undefined && answer?.imageUrl !== '' && 'background-image-cover'}
            ${answer?.userAnswer!==undefined ? 'selected' : ''}`}
                    
            style={answer?.imageUrl !== undefined && answer?.imageUrl !== '' ? {backgroundImage: `linear-gradient(to bottom, 
                        rgba(0, 0, 0, 0) 30%, 
                        var(--dark-4)), 
                        url(${answer.imageUrl})
                        `} : {}}>

            <div className="main">
                <div className="details answer">

                    {variant==='input-toggle' && (
                    <div>
                        {Object.keys(answer).length === 0 && ( /* answer object exists but not set (answer has not been picked) */
                            <Button variant={`icon inline square ${!isExpanded && canSubmit && 'primary'}`} onClick={handleInputClick}>
                                {isExpanded ? (<IndeterminateCheckBoxIcon />) : (<AddBoxIcon />)}
                            </Button>
                        )}

                        {Object.keys(answer).length !== 0 && ( 
                            <Button variant='icon inline square' onClick={handleInputClick}>
                                {isExpanded ? (<IndeterminateCheckBoxIcon />) : (<AddBoxIcon />)}
                            </Button>
                        )}
                    </div>
                    )}

                    {variant==='input-pick' && (
                    <div className='flex flex-column align-items-center'>
                        {(!canSubmit && isAdmin) ? (<Button variant='link' onClick={handleInputClick}>Pick</Button>) : (
                        <label className='padding-0'>Pick</label>
                        )}
                        <Button variant='primary icon' onClick={handleInputClick} disabled={!canSubmit}><DoubleArrowIcon fontSize='small' /></Button>
                    </div>
                    )}

                    <div className='flex-grow-1 answer-content'>
                        {answer?.answerId === undefined && (
                        <div>Select Answer</div>
                        )}

                        {answer?.text?.length > 0 && (<>
                        <div className='flex flex-grow-1 gap-05'>
                            <div className='flex-grow-1'>{answer.text}</div>

                            {answer?.points !== undefined && (
                            <div className='points'>
                                <Button variant='icon' onClick={openPointsBreakdown}>
                                    <Chip>{answer.points?.toFixed(1)}</Chip>
                                </Button>
                            </div>
                            )}                            
                        </div>

                        </>)}
                        

                    </div>
                </div>
                
                

            </div>
            
                    
            {variant==='responses' && (<>
            <div className="submission-avatars" onClick={(e) => responsesClick(e, submissions)}>
                {submissions.map(user => (
                <UserChip variant='avatar' user={user}></UserChip>
                ))}
            </div>
            </>)}

        </div>
        )}
    </>);
}

export default Answer;
