import React, { useEffect, useState } from 'react'

import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import Input from '../../atoms/Input/Input';

import Loader from '../Loader/Loader';
import './PictureChooser.css';

function PictureChooser({config, onHide}) {
    const [error, setError] = useState('');
    const [url, setUrl] = useState('');
    const [validUrl, setValidUrl] = useState('');
  
    useEffect(() => {
        isUrlValid();
    }, [url]);
    
    const isUrlValid = async () => {

        if(url.length === 0) {
            setError('');
            setValidUrl('');
            return Promise.resolve(false);

        } else if(url.length > 500) {
            setValidUrl('');
            setError('URL must be less than 500 characters');
            return Promise.resolve(false);

        } else if(!url.startsWith('http')) {
            setValidUrl('');
            setError('URL should start with http');
            return Promise.resolve(false);

        } else if(!await isUrlAnImage(url)) {
            setValidUrl('');
            setError('URL is not valid');
            return Promise.resolve(false);

        } else {
            setValidUrl(url);
            setError('');
            return Promise.resolve(true);
        }
    }
    const isUrlAnImage = (url) => { 
        return new Promise((resolve) => { 
            const img = new Image(); 
            img.onload = () => resolve(true); 
            img.onerror = () => resolve(false); 
            img.src = url; 

        }); 
    }

    const handleCloseEvent = () => {
        onHide(validUrl);
        setUrl('');
        setValidUrl('');
    }


    const isDirty = () => {
        if(validUrl !== '') {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true} className='full-width'>
            <div className='flex flex-column gap-05'>
                <div className='flex justify-content-between'>
                    <h2>Choose Picture</h2>
                    <Button variant="primary" onClick={handleCloseEvent} disabled={!isDirty()}>Save</Button>
                </div>
                <div className='flex flex-column gap-1'>
                    <Input
                        name='Enter URL'
                        type='textarea'
                        value={url}
                        onChange={(e) => setUrl(e.target.value)} />
                        

                    <div className='dark-card'>
                        <label>Preview</label>
                        <div className='grid justify-items-center'>
                            <img className="img-preview" src={validUrl} alt="Preview" />
                        </div>
                    </div>
                </div>
            </div>

            <div style={{color: 'red'}}>{error}</div>
            <div className="modal-footer">
                <Button variant="secondary" onClick={handleCloseEvent}>Cancel</Button>
            </div>
        </Modal>
    )
}

export default PictureChooser;