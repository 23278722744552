import React, { useState, useEffect } from 'react';

import UserChip from '../../../../components/UserChip/UserChip';
import Driver from '../../../../components/Driver/Driver';

import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';

import './DriverResponses.css';


function DriverResponses({questionId, eventId}) {
	const [groupAPicks, setGroupAPicks] = useState([]);
	const [groupBPicks, setGroupBPicks] = useState([]);
	const [groupCPicks, setGroupCPicks] = useState([]);

	const [answerSubmissions, setAnswerSubmissions] = useState({show: false, submissions: []});
	
	useEffect(() => {
		fetchResponses();
	}, [questionId, eventId]);

	
	const fetchResponses = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				eventId: eventId
            })
        };
    
		fetch(apiUrl + `/app/form/questionResponses`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setGroupAPicks(data.filter(pick => pick.order === 1));
				setGroupBPicks(data.filter(pick => pick.order === 2));
				setGroupCPicks(data.filter(pick => pick.order === 3));
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const showAnswerSubmissions = (e, submissions) => {
		setAnswerSubmissions({show: true, submissions: submissions});
		console.log(`showAnswerSubmissions`);
		console.log(submissions);
	}
	const closeAnswerSubmissions = () => {
		setAnswerSubmissions({show: false, submissions: []});
	}

    return (<>
        <Modal show={answerSubmissions.show} onHide={closeAnswerSubmissions} center="true" closeOnOverlayClick="true">
            <h2>Picks</h2>
            
            <div className="answer-responses-container">
                {answerSubmissions.submissions.map(user => (
                  <UserChip user={user}></UserChip>
                ))}
            </div>

            
            <div className="modal-footer">
                <Button variant="secondary" onClick={closeAnswerSubmissions}>Close</Button>
            </div>
        </Modal>

		<div className='flex justify-content-between'>
			<h3>Group A</h3>
		</div>
		<div className='selection-container'>
			{groupAPicks.map(a => (
			<Driver driver={a.driver} variant='responses'
				answer={{questionId, answerId: a.answerId, points: a.points, projectedPoints: a.projectedPoints}}
				eventId={eventId}
				group='A'
				responsesClick={showAnswerSubmissions}
				submissions={a.submissions}></Driver>
			))}
		</div>
		
		<div className='flex justify-content-between'>
			<h3>Group B</h3>
		</div>
		<div className='selection-container'>
			{groupBPicks.map(a => (
			<Driver driver={a.driver} variant='responses'
				answer={{questionId, answerId: a.answerId, points: a.points, projectedPoints: a.projectedPoints}}
				eventId={eventId}
				group='B'
				responsesClick={showAnswerSubmissions}
				submissions={a.submissions}></Driver>
			))}
		</div>
      
		<div className='flex justify-content-between'>
			<h3>Group C</h3>
		</div>
		<div className='selection-container'>
			{groupCPicks.map(a => (
			<Driver driver={a.driver} variant='responses'
				answer={{questionId, answerId: a.answerId, points: a.points, projectedPoints: a.projectedPoints}}
				eventId={eventId}
				group='C'
				responsesClick={showAnswerSubmissions}
				submissions={a.submissions}></Driver>
			))}
		</div>
    </>);
    
}

export default DriverResponses;