import React, { useState, useEffect } from 'react';

import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import Select from '../../atoms/Select/Select';

import Loader from '../../components/Loader/Loader';
import CustomizeUserChip from '../../components/UserChip/CustomizeUserChip';

import PlaceGlyph from '../../atoms/PlaceGlyph/PlaceGlyph';
import TabGroup from '../../atoms/Tabs/TabGroup';
import Tab from '../../atoms/Tabs/Tab';


import './Profile.css';

function Profile({user, setUser}) {
    const [updatedUser, setUpdatedUser] = useState(user);
    const [year, setYear] = useState(2025);
    const [yearOptions, setYearOptions] = useState([2025, 2024, 2023]);
    const [userSeasonStats, setUserSeasonStats] = useState([]);
    const [badges, setBadges] = useState([]);
    const [canSave, setCanSave] = useState(false);
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState({show: false, text: ''});

    useEffect(() => {
        setUpdatedUser(user);
        fetchUserSeasonStats(year);
        fetchUserBadges(user);
    }, [user]);

    useEffect(() => {
        fetchUserSeasonStats(year);
    }, [year]);

    

  const handleProfileChange = (event) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let tempProfile = JSON.parse(JSON.stringify(updatedUser)); 
    console.log(`handleProfileChange [${event.target.name}]`);

    setMessage('');
    let isValid = false;

    if (event.target.name === 'First Name') {
        tempProfile.firstName = event.target.value;

        if (tempProfile.firstName === '') {
            setMessage('First name must not be blank');
        } else {
            isValid = true;
        }
        
    } else if (event.target.name === 'Last Name') {
        tempProfile.lastName = event.target.value;

        if (tempProfile.lastName === '') {
            setMessage('Last name must not be blank');
        } else {
            isValid = true;
        }

    } else if (event.target.name === 'Email') {
        tempProfile.email = event.target.value;
    }
    setUpdatedUser(tempProfile);

    let isDirty = JSON.stringify(user) !== JSON.stringify(tempProfile);
    if(isDirty && isValid) {
        setCanSave(true);
    } else {
        setCanSave(false);
    }
  }


  const fetchUserSeasonStats = (year) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              userId: user.userId,
              year: year
          })
      };

      fetch(apiUrl + "/app/profile/userSeasonStats", requestOptions)
          .then(response => response.json())
          .then(data => {
              setUserSeasonStats(data);
          })
      .catch((err) => {
          console.log(err);
          setMessage('Unable to fetch userSeasonStats.');
      });
  }

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    fetchUserSeasonStats(selectedYear);
  }


  const fetchUserBadges = (user) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              userId: user.userId
          })
      };

      fetch(apiUrl + "/app/profile/badges", requestOptions)
          .then(response => response.json())
          .then(data => {
              console.log('badges:', data);
              setBadges(data);
          })
      .catch((err) => {
          console.log(err);
          setMessage('Unable to fetch badges.');
      });
  }
    
    
    const handleSave = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userId: updatedUser.userId, 
                firstName: updatedUser.firstName, 
                lastName: updatedUser.lastName, 
                displayName: updatedUser.displayName, 
                email: updatedUser.email, 
                avatarUrl: updatedUser.avatarUrl,
                color: updatedUser.color
            })
        };

        setLoader({show: true, text: 'Saving profile...'});
        fetch(apiUrl + "/user/update", requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log('saveuser data:', data);
                setUser(updatedUser);
                setCanSave(false);
                setLoader({show: false});
            })
        .catch((err) => {
            console.log(err);           
            setLoader({show: false}); 
            setMessage('Unable to save profile.');
        });
    }

    const [activeTab, setActiveTab] = useState('Stats');
    const handleTabChange = (newTab) => {
        console.log(newTab)
        setActiveTab(newTab);
    }


    return (
        <div className="page-container">
            <Loader config={loader}></Loader>

            <TabGroup>
                <Tab label='Stats' activeTab={activeTab} onClick={handleTabChange} />
                {user.isAdmin && <Tab label='Badges' activeTab={activeTab} onClick={handleTabChange} /> }                
                <Tab label='Customize' activeTab={activeTab} onClick={handleTabChange} />
                <Tab label='Account' activeTab={activeTab} onClick={handleTabChange} />
            </TabGroup>

            <div className='tab-outlet flex align-items-center'>
                {activeTab === "Stats" && (<>
                    <div className="dark-card">
                        <div className="flex justify-content-between align-items-end">
                            <h3>Season Stats</h3>
                            {user.isAdmin && (
                            <Select 
                                options={yearOptions}
                                value={year}
                                clickFunction={handleYearChange}
                            >
                            </Select>
                            )}
                        </div>
                        <div className='table-container'>
                            <table className='table'>
                                <thead>
                                    <th>Round</th>
                                    <th>Race</th>
                                    <th>Points</th>
                                    <th>Place</th>
                                </thead>
                                <tbody>
                                {userSeasonStats.length !== 0 && userSeasonStats.map(round => (
                                    <tr>
                                        <td>{round.round}</td>
                                        <td>
                                            <div className='flex align-items-center gap-1'>
                                                <img className="flag small" alt={round.locality} src={round.flagUrl} />
                                                <img className="circuit-map small" alt={round.name} src={round.trackSvgUrl} />
                                                <div>{round.name}</div>
                                            </div>
                                        </td>
                                        <td>{round.points}</td>
                                        <td><PlaceGlyph place={round.place} /></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    {/* <div className="dark-card">
                        <h3>Past Seasons</h3>
                    </div> */}
                </>)}

                {activeTab === "Badges" && (<>
                    <div className="dark-card">
                        <h3>Badges</h3>
                        <div className='badge-container'>
                            {badges.length !== 0 && badges.map(b => (
                                <div className='badge'>
                                    <div>{b.svg}</div>
                                    <div className='counter'>{b.count}</div>
                                    <div>{b.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>)}

                {activeTab === "Customize" && (<>
                    <div className="dark-card">
                        {/* <h3>Customize</h3> */}
                        <CustomizeUserChip user={user} setUser={setUser}></CustomizeUserChip>
                    </div>
                </>)}

                {activeTab === "Account" && (<>
                    <div className="dark-card">
                        <div className='flex justify-content-between'>
                            <h3>Account</h3>                            
                            <Button variant="primary" onClick={handleSave} value="Save" className="save-btn" disabled={!canSave}>Save</ Button>
                        </div>

                        <form className='account' onSubmit={e => {e.preventDefault();}}>
                            <Input 
                                type="fname" 
                                value={updatedUser.firstName} 
                                onChange={handleProfileChange} 
                                name="First Name" />
                            
                            <Input 
                                type="lname" 
                                value={updatedUser.lastName} 
                                onChange={handleProfileChange} 
                                name="Last Name" />
                            
                            <Input 
                                type="email" 
                                value={updatedUser.email} 
                                onChange={handleProfileChange} 
                                name="Email" 
                                disabled />

                            <p>{message}</p>
                        </form>
                    </div>
                </>)}
            </div>

        </div>
    )
}

export default Profile;