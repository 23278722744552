import React from 'react';

import './skeleton.css';
/**
 * shadow = the object which the skeleton is skeleton-ing for (appears if undefined)
 * variant = driver/constructor
 * input = true/false
 * responses = true/false
 */
function Skeleton({variant, className}) {
	return (
		<div variant={variant} className={`dark-card skeleton${className !== undefined ? ` ${className}` : ''}`}>
			<div className='main'>
				<div className='header-text'></div>
			</div>
		</div>
	);
}
export default Skeleton;