import React, { useEffect, useState } from 'react';

import Skeleton from '../../../components/Skeleton/Skeleton';
import ScheduleSettings from './ScheduleSettings/ScheduleSettings';
import Button from '../../../atoms/Button/Button';
import SettingsIcon from '@mui/icons-material/Settings';

import './ScheduleTab.css';

function ScheduleTab({user, eventId}) {
	const [schedule, setSchedule] = useState([]);
	const [circuit, setCircuit] = useState({});
    const [scheduleSettingsConfig, setScheduleSettingsConfig] = useState({show: false, eventId: 0});  
    
    
	useEffect(() => {
		console.log('ScheduleTab', eventId);
		if(eventId !== undefined && eventId !== null) {
			fetchSchedule();
			fetchCircuit();
		}
	}, [user, eventId]);

	const fetchSchedule = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
	
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				eventId: eventId
			})
		};
	
		fetch(apiUrl + `/app/ref/relatedEvents`, requestOptions)
			.then(response => response.json())
			.then(data => {
				data.forEach(d => d.datetime = new Date(d.date));
				setSchedule(data.sort((a, b) => b.datetime - a.datetime));
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const fetchCircuit = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
	
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				eventId: eventId
			})
		};
	
		setCircuit();	//Setting undefined for the skeleton loader

		fetch(apiUrl + `/app/ref/eventCircuit`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setCircuit(data);
			})
			.catch((err) => {
				setCircuit();	// keep the skeleton on if the circuit is missing.
				console.log(err);
			});
	}


    const openScheduleSettings = () => {
        setScheduleSettingsConfig({
            show: true, 
            eventId: eventId
        });
    }
    const hideScheduleSettings = (action) => {
        setScheduleSettingsConfig({show: false});
		if(action === 'Reload') {
			fetchSchedule();
		}
    }

    

    return (
    <div className='event-details'>
        <ScheduleSettings config={scheduleSettingsConfig} onHide={hideScheduleSettings}></ScheduleSettings>

		{schedule.length === 0 ? (<Skeleton variant='question' />) : (<> 
        <div className='dark-card'>
            <div className='flex justify-content-between'>
                <h2>Schedule</h2>

                {user.isAdmin && (
                <Button variant='icon' onClick={openScheduleSettings}>
                    <SettingsIcon fontSize="small" />
                </Button>
                )}

            </div>
            
            <div className='grid gap-1'>
                {schedule.map(s => (
                    <div className='col-2'>
                        <div><b>{s.type}</b></div>
                        <div className='grid justify-items-end'>
                            <div>{`${s.datetime.toLocaleString([], {weekday: 'short' })}, ${s.datetime.toLocaleString([], {month: 'short', day: 'numeric' })}`}</div>
                            <div>{`${s.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
		</>)}

		{circuit === undefined ? (<Skeleton variant='question' />) : (<> 
        <div className='dark-card'>
            <h2>{circuit.name}</h2>
            <div>{`${circuit.locality}, ${circuit.country}`} <img className="flag small" alt={circuit.locality} src={circuit.flagUrl} /></div>

            <img className="track-map" alt="track" src={circuit.trackUrl} />
        </div>
		</>)}
    </div>
    );
}

export default ScheduleTab;