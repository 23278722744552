import React, { useReducer, useState, useEffect } from 'react';

import Button from '../../../atoms/Button/Button';
import Input from '../../../atoms/Input/Input';
import Select from '../../../atoms/Select/Select';

import Loader from '../../../components/Loader/Loader';
import PictureChooser from '../../../components/PictureChooser/PictureChooser';
import Constructor from '../../../components/Constructor/Constructor';

import ConstructorSettings from './ConstructorSettings/ConstructorSettings';

import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import './ConstructorsTab.css';


function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload?.index})`);   
    
    let newState = structuredClone(state);
    try {

        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'update':
                let newState = structuredClone(state);
                const updated = newState.find(constructor => constructor.constructorId === action.payload.id);

                if(updated !== undefined) {
                    if(action.payload.property === 'Active') {
                        updated.active = action.payload.value;
                    } else {
                        console.log(`Property ${action.payload.property} not known.`);
                    }
                } else {
                    console.log(`ID ${action.payload.id} not known.`);
                }

                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}



function ConstructorsTab() {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [constructorSettingsConfig, setConstructorSettingsConfig] = useState({show: false});
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});
    
    const [activeConstructors, dispatchActive] = useReducer(reducer, []);
    const [dbDataActive, setDbDataActive] = useState([]);

    const [inactiveConstructors, dispatchInactive] = useReducer(reducer, []);
    const [dbDataInactive, setDbDataInactive] = useState([]);
    
    const [filterOptions, setFilterOptions] = useState(['Active', 'Inactive']);
    const [currentFilter, setCurrentFilter] = useState('Active');


    useEffect(() => {
        console.log('useEffect ConstructorsTab');
        fetchConstructors();
    }, []);

    const fetchConstructors = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        setLoader({show: true, text: 'Loading constructors...'});
        fetch(apiUrl + `/app/ref/constructors`, requestOptions)
        .then(response => response.json())
        .then(data => {
            data = data.sort((a, b) => a.name.localeCompare(b.name));

            let active = data.filter(c => c.active === true);
            setDbDataActive(active);
            dispatchActive({type: 'initialize', payload: active});

            let inactive = data.filter(c => c.active === false);
            setDbDataInactive(inactive);
            dispatchInactive({type: 'initialize', payload: inactive});

            setLoader({show: false});        
        })
        .catch((err) => {
            console.log(err);            
            setLoader({show: false});
        });
    }

    const filteredConstructors = () => {
        if(currentFilter === 'Active') {
            return activeConstructors;
        } else if(currentFilter === 'Inactive') {
            return inactiveConstructors;
        } 
    }

    // const openPictureChooser = (index, property) => {
    //     setPictureChooserConfig({show: true, index, property});
    // }

    // const closePictureChooser = (url) => {
    //     console.log(`url (${url.length})`, url);
        
    //     if(url.length > 0) {
    //         let index = pictureChooserConfig.index;
    //         let property = pictureChooserConfig.property;
            
    //         dispatch({type: 'update_constructor', payload: {index, property, value: url}});
    //     }
        
    //     setPictureChooserConfig({show: false});
    // }

    // const handleConstructorChange = (property, value, i) => {
    //     console.log(`handleConstructorChange [${property}] => ${value} [${i}]`);
        
    //     dispatch({type: 'update_constructor', payload: {index: i, property: property, value: value}});
    // }


	const openConstructorSettings = (e, constructorId) => {
        e.preventDefault();

        if(constructorId === undefined) {    // add new constructor
            let active = currentFilter === 'Active';
            setConstructorSettingsConfig({show: true, active: active});
        } else {
            setConstructorSettingsConfig({show: true, constructorId: constructorId});
        }
    }
    const closeConstructorSettings = (action) => {
        if(action === 'Reload') {
            fetchConstructors();
        }
        setConstructorSettingsConfig({show: false});
    }

    const handlePropertyChange = (property, value, id) => {
        console.log(`handlePropertyChange [${property}] => ${value} [${id}]`);
        
        if(currentFilter === 'Active') {
            dispatchActive({type: 'update', payload: {id: id, property: property, value: value}});
        } else if(currentFilter === 'Inactive') {
            dispatchInactive({type: 'update', payload: {id: id, property: property, value: value}});
        }
    }
    
    const save = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                constructors: [...activeConstructors, ...inactiveConstructors]
            })
        };

        setLoader({show: true, text: 'Saving constructors...'});
        fetch(apiUrl + "/app/ref/saveConstructors", requestOptions)
          .then(response => {
            if(response.status === 200) {
                setLoader({show: false});
                fetchConstructors();
            }
          })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }

    const isDirty = () => {
        return JSON.stringify([...activeConstructors, ...inactiveConstructors]) !== JSON.stringify([...dbDataActive, ...dbDataInactive])
    }

    return (
        <div className='settings-event-tab'>
            <Loader config={loader}></Loader>
            {/* <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser> */}
            <ConstructorSettings config={constructorSettingsConfig} onHide={closeConstructorSettings}></ConstructorSettings>

            <div className='flex justify-content-between' style={{marginInline: '1em'}}>
                <Select 
                    name="Type" 
                    options={filterOptions}
                    value={currentFilter}
                    clickFunction={(value) => setCurrentFilter(value)}
                    showLabel={false} />
                <Button variant='outline' onClick={(e) => openConstructorSettings(e)}>Add Constructor</Button>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>
            
            <div className="overflow-auto padding-1">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Constructor</th>
                            <th></th>
                            <th>Active</th>
                        </tr>
                    </thead>
                    <tbody>
                        { filteredConstructors().map((c, index) => (
                        <tr>
                            <td>
                                <Constructor constructor={c} showStats={false}></Constructor>
                            </td>
                            <td>
                                <Button variant='icon' onClick={(e) => openConstructorSettings(e, c.constructorId)}><SettingsIcon /></Button>
                            </td>
                            <td>
                                <Input name="Active" 
                                    type="checkbox" 
                                    checked={c.active}
                                    onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, c.constructorId)}
                                    showLabel={false} />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ConstructorsTab;