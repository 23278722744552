import React, { useReducer, useState, useEffect } from 'react';

import Button from '../../../atoms/Button/Button';
import Select from '../../../atoms/Select/Select';

import Loader from '../../../components/Loader/Loader';
import EventSettings from '../../../components/EventSettings/EventSettings';

import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';

import './EventsTab.css';


function EventsTab() {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [year, setYear] = useState();
    const [yearOptions, setYearOptions] = useState([2025, 2024, 2023]);
    const [eventType, setEventType] = useState();
    const [eventTypeOptions, setEventTypeOptions] = useState([]);
    const [eventSettingsConfig, setEventSettingsConfig] = useState({show: false});
    const [events, setEvents] = useState([]);

    useEffect(() => {

        let year = localStorage.getItem('year') ? parseInt(localStorage.getItem('year'), 10) : null ?? 2025;
        let eventType = localStorage.getItem('eventType') ?? 'Grand Prix';

        setYear(year);
        setEventType(eventType);
        console.log(`useEffect EventsTab ${year} ${eventType}`);
        
        fetchEvents(year, eventType);
        fetchEventTypes();
    }, []);

    const fetchEvents = (season, eventType) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                season: season === '' ? null : season,
                type: eventType === '' ? null : eventType
            })
        };

        setLoader({show: true, text: 'Loading events...'});
        fetch(apiUrl + `/app/ref/events`, requestOptions)
        .then(response => response.json())
        .then(data => {
            const ordered = data.sort((a, b) => { return a.date - b.date; });

            setEvents(ordered);
            setLoader({show: false});            
        })
        .catch((err) => {
            console.log(err);            
            setLoader({show: false});
        });
    }

    const fetchEventTypes = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        fetch(apiUrl + `/app/ref/eventTypes`, requestOptions)
        .then(response => response.json())
        .then(data => {
            let types = data.map(t => t.type)
            setEventTypeOptions(types);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const formatDateString = (datestring) => {
        let date = new Date(datestring);

        let localDatetimeString = `${date.toLocaleString([], {weekday: 'short' })}, ${date.toLocaleString([], {month: 'short', day: 'numeric' })}, ${date.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`

        return localDatetimeString;
    }

    const handleYearChange = (selectedYear) => {
        setYear(selectedYear);
        localStorage.setItem('year', selectedYear);
        fetchEvents(selectedYear, eventType);
    }

    const handleEventTypeChange = (selectedEventType) => {
        setEventType(selectedEventType);
        localStorage.setItem('eventType', selectedEventType);
        fetchEvents(year, selectedEventType);
    }

	const openEventSettings = (e, eventId) => {
        e.preventDefault();
		setEventSettingsConfig({
			show: true, 
			eventId: eventId,
            season: year
		});
	}

	const hideEventSettings = (action) => {
		setEventSettingsConfig({show: false});
		if(action === 'Reload') {
			fetchEvents(year, eventType);
		}
	}

    return (
        <div className='settings-event-tab'>
            <Loader config={loader}></Loader>
            <EventSettings config={eventSettingsConfig} onHide={hideEventSettings}></EventSettings>

            <div className='col-2 flex-shrink-0'>
                <Select 
                    options={yearOptions}
                    value={year}
                    clickFunction={handleYearChange}
                >
                </Select>
                <Select 
                    options={eventTypeOptions}
                    value={eventType}
                    clickFunction={handleEventTypeChange}
                >
                </Select>
            </div>

            <div className="event-container">
            { events.length > 0 && events.map(event => (
                <div className='dark-card grid grid-template-x'>
                    <div className='inline-grid grid-area-x event'>
                        <img className="poster-thumbnail" 
                            src={event.posterUrl} alt="?"
                            style={{border: `2px solid ${event.accentColor || '#000000'}`}} />
                        <div>
                            <div className='single-line'><b>{`R${event.round}: ${event.name}`}</b></div>
                            <div>{formatDateString(event.date)}</div>
                            <div>{event.type}</div>
                        </div>
                    </div>
                    <div className='grid grid-area-x justify-self-end'>
                        <Button variant='icon' onClick={(e) => openEventSettings(e, event.eventId)}><SettingsIcon /></Button>
                    </div>
                </div>
            ))}
            </div>

            <Button variant='outline' onClick={(e) => openEventSettings(e)}>Add Event</Button>
        </div>
    )
}

export default EventsTab;