import React, { useState, useEffect } from 'react';
import { auth, logInWithEmailAndPassword } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import ReCAPTCHA from "react-google-recaptcha";
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import Reset from "./Reset";

import './Auth.css';
 
const EmailSignIn = ({onClose}) => {
 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState("");
    const [captchaPassed, setCaptchaPassed] = useState(false);
    const [user, loading, error] = useAuthState(auth);

    const [showReset, setShowReset] = useState(false);
    const openReset = () => setShowReset(true);
    const closeReset = (action) => {
        setShowReset(false);
    }

    // useEffect(() => {
    //     if (email!=='' && user) {
    //         console.log('user',user.email)
    //         setEmail('');

    //         if (user.emailVerified) {
    //             onClose();
    //         } else {
    //             onClose('Open Verify Email');
    //         }
    //     }
    // }, [user, loading, error, onClose]);

    const onSubmit = async () => {     
        if (captchaPassed) {
            try {
                console.log('logInWithEmailAndPassword');

                await logInWithEmailAndPassword(email, password);
                onClose();
            } catch (error) {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);

                if(errorCode === 'auth/user-not-found') {
                    setMessage('No account found for this email.');

                } else if(errorCode === 'auth/wrong-password') {
                    setMessage('Wrong password or sign-in method for this email.');

                } else if(errorCode === 'auth/user-disabled') {
                    setMessage('This user account has been disabled. Please contact support.');

                } else {
                    setMessage(`Error: ${errorCode}. Please contact support.`);
                }
            }


        } else {
            // Show an error message or prevent form submission
            console.log('Please complete the reCAPTCHA.');
        }
    }
    const handleRecaptchaChange = (token) => {
        // Handle the reCAPTCHA token (e.g., send it to your server for verification)
        // console.log('reCAPTCHA token:', token);
        setCaptchaPassed(true);
      };
    
    const handleOpenSignIn = () => {onClose('Open Sign In');}

    const handleOpenSignUp = () => {onClose('Open Sign Up');}

    return (
        <div className='auth-popup-container'>
            <Modal show={showReset} onHide={closeReset} closeOnOverlayClick={false}>
                <Reset onClose={closeReset}></Reset>
            </Modal>

            <h2 className='text-align-center'>Email Sign In</h2>

            <p className='text-align-center'>
                Don't have an account? <Button variant="link" onClick={handleOpenSignUp}>Sign Up</Button>
            </p>

            <form onSubmit={e => {e.preventDefault();}}>
                <div className='form-field'>
                    <label htmlFor="email-address">
                        Email Address
                    </label>
                    <input
                        type="email"
                        label="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}  
                        required
                        placeholder="Email address"
                    />
                </div>

                <div className='form-field'>
                    <label htmlFor="password">
                        Password
                    </label>
                    <input
                        type="password"
                        label="Create password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Password"
                    />
                </div>
            </form>
            {/* keep this button outside the form to prevent the auto credential input from triggering the reset popup to open */}
            <Button variant="link" onClick={openReset}>Forgot Password?</Button>

            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={handleRecaptchaChange} />

                
            {message!=='' && (
                <p style={{color: 'red'}}>{message}</p>
            )}

            <Button variant="primary" onClick={onSubmit} disabled={email==='' || password==='' || !captchaPassed}>Continue</Button>

            <Button variant="outline" onClick={handleOpenSignIn}>Back</Button>
        </div>
    )
}
 
export default EmailSignIn