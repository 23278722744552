import React, { useState, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import Skeleton from '../Skeleton/Skeleton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Chip from '../../atoms/Chip/Chip';
import './DriverStats.css';

const DriverStats = ({config, onHide}) => {
    const [driver, setDriver] = useState({});
    const [stats, setStats] = useState([]);
    
    useEffect(() => {
        if(config.show) {
            console.log('config', config);
            fetchDriver();
            fetchStats();
        }

    }, [config]);

    const fetchDriver = async () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        
		const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                driverId: config.driverId
            })
        };

		try {
			const response = await fetch(apiUrl + `/app/ref/eventDriver`, requestOptions);
            const data = await response.json();
			setDriver(data);

        } catch (err) {
            console.log(err);
        }
    }

    const fetchStats = async () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                driverId: config.driverId
            })
        };

		try {
			const response = await fetch(apiUrl + `/app/ref/driver/stats`, requestOptions);
            const data = await response.json();
			setStats(data);

        } catch (err) {
            console.log(err);
        }
    }


    const getAge = (dob) => {
        const currentDate = new Date();
        const dobDate = new Date(dob);

        let age = currentDate.getFullYear() - dobDate.getFullYear(); 
        const month = currentDate.getMonth() - dobDate.getMonth(); 

        if (month < 0 || (month === 0 && currentDate.getDate() < dobDate.getDate())) {
            age--; 
        }
        
        return age;
    }
	
	const formatPlace = (startPosition, currentPosition, status) => {
		const posChange = startPosition - currentPosition;
		let formattedPosChange;

		if (posChange > 0) { 
			formattedPosChange = `+${posChange}`; } 
		else if (posChange < 0) { 
			formattedPosChange = `${posChange}`; 
		} else { 
			formattedPosChange = '—'; 
		}

		if(status === null) {
			return (
			<div className='flex gap-05'>
				<span>P{currentPosition}</span>
				<span style={posChange===0 ? {color: 'gray'}: (posChange>0 ? {color: 'green'} : {color: 'red'})}>{formattedPosChange}</span>
			</div>
			)
		} else {
			return (<span>{status}</span>);
		}
	}

	return (
		<Modal show={config.show} onHide={onHide} closeOnOverlayClick={true} className='gap-1'>
			
			{Object.keys(driver).length === 0 ? (<Skeleton variant='driver-card' className='full-width' />) : ( 
			<div className='dark-card full-width'>
				<div className='summary-container'>
					<div>
						<img className='rounded-square medium' src={driver.profilePicUrl} alt={driver.lastName} />
					</div>
					<div>
						<div className='flex align-items-center gap-1'>
							<h2 className='single-line'>{driver.firstName} {driver.lastName}</h2>
						</div>
						<div className='flex align-items-center gap-1'>
							<p>#{driver.driverNumber}</p>
							<img className='flag' src={driver.flagUrl} alt={driver.nationality} />
							<p className='age'>Age: {getAge(driver.dob)}</p>
						</div>
						<div className='flex align-items-center gap-05'>
							<img className='pill tiny' style={{backgroundColor: `${driver.constructor?.accentColor}`, padding: '2px'}} src={driver.constructor?.logoUrl} alt={driver.constructor?.name} />
							<p>{driver.constructor?.name}</p>
						</div>
					</div>
				</div>
			</div>
			)}
			
			<div className='table-container'>
				<table className='table table-layout-fixed'>
					<colgroup>
						<col col-width='icon' />
						<col col-width='icon' />
						<col col-width='max-content' />
						<col col-width='short-number' />
					</colgroup>
					<thead>
						<th></th>
						<th></th>
						<th>Race</th>
						<th>Place</th>
					</thead>
					<tbody>
						{stats.map((stat, index) => (
							<tr>
								<td>R{stat.round}</td>
								<td><img className="flag small" alt={stat.locality} src={stat.flagUrl} /></td>
								<td><div className='single-line'>{stat.name}</div></td>
								<td>{formatPlace(stat.startPosition, stat.endPosition, stat.status)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

		</Modal>
	);
}

export default DriverStats;
