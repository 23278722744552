import React, { useReducer, useState, useEffect } from 'react';

import Button from '../../../atoms/Button/Button';
import Input from '../../../atoms/Input/Input';
import Select from '../../../atoms/Select/Select';

import Loader from '../../../components/Loader/Loader';
import Driver from '../../../components/Driver/Driver';

import DriverSettings from './DriverSettings/DriverSettings';

import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import './DriversTab.css';

function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload?.index})`);   
    
    try {
        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'update':
                let newState = structuredClone(state);
                const updated = newState.find(driver => driver.driverId === action.payload.id);

                if(updated !== undefined) {
                    if(action.payload.property === 'Active') {
                        updated.active = action.payload.value;
                    } else {
                        console.log(`Property ${action.payload.property} not known.`);
                    }
                } else {
                    console.log(`ID ${action.payload.id} not known.`);
                }

                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return state;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return state;
    }
}



function DriverTab() {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [driverSettingsConfig, setDriverSettingsConfig] = useState({show: false});

    const [activeDrivers, dispatchActive] = useReducer(reducer, []);
    const [dbDataActive, setDbDataActive] = useState([]);

    const [inactiveDrivers, dispatchInactive] = useReducer(reducer, []);
    const [dbDataInactive, setDbDataInactive] = useState([]);

    const [filterOptions, setFilterOptions] = useState(['Active', 'Inactive']);
    const [currentFilter, setCurrentFilter] = useState('Active');


    useEffect(() => {
        console.log('useEffect DriversTab');
        fetchDrivers();
    }, []);

    const fetchDrivers = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        setLoader({show: true, text: 'Loading drivers...'});
        fetch(apiUrl + `/app/ref/drivers`, requestOptions)
        .then(response => response.json())
        .then(data => {
            data = data.sort((a, b) => a.lastName.localeCompare(b.lastName));

            let active = data.filter(d => d.active === true);
            setDbDataActive(active);
            dispatchActive({type: 'initialize', payload: active});

            let inactive = data.filter(d => d.active === false);
            setDbDataInactive(inactive);
            dispatchInactive({type: 'initialize', payload: inactive});

            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);            
            setLoader({show: false});
        });
    }

    const filteredDrivers = () => {
        if(currentFilter === 'Active') {
            return activeDrivers;
        } else if(currentFilter === 'Inactive') {
            return inactiveDrivers;
        } 
    }


	const openDriverSettings = (e, driverId) => {
        e.preventDefault();

        if(driverId === undefined) {    // add new driver
            let active = currentFilter === 'Active';
            setDriverSettingsConfig({show: true, active: active});
        } else {
            setDriverSettingsConfig({show: true, driverId: driverId});
        }
    }
    const closeDriverSettings = (action) => {
        if(action === 'Reload') {
            fetchDrivers();
        }
        setDriverSettingsConfig({show: false});
    }

    const handlePropertyChange = (property, value, id) => {
        console.log(`handlePropertyChange [${property}] => ${value} [${id}]`);
        
        if(currentFilter === 'Active') {
            dispatchActive({type: 'update', payload: {id: id, property: property, value: value}});
        } else if(currentFilter === 'Inactive') {
            dispatchInactive({type: 'update', payload: {id: id, property: property, value: value}});
        }
    }
    
    const save = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                drivers: [...activeDrivers, ...inactiveDrivers]
            })
        };

        setLoader({show: true, text: 'Saving drivers...'});
        fetch(apiUrl + "/app/ref/saveDrivers", requestOptions)
          .then(response => {
            if(response.status === 200) {
                setLoader({show: false});
                fetchDrivers();
            }
          })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }

    const isDirty = () => {
        return JSON.stringify([...activeDrivers, ...inactiveDrivers]) !== JSON.stringify([...dbDataActive, ...dbDataInactive])
    }

    return (
        <div className='settings-event-tab'>
            <Loader config={loader}></Loader>
            <DriverSettings config={driverSettingsConfig} onHide={closeDriverSettings}></DriverSettings>

            <div className='flex justify-content-between' style={{marginInline: '1em'}}>
                <Select 
                    name="Type" 
                    options={filterOptions}
                    value={currentFilter}
                    clickFunction={(value) => setCurrentFilter(value)}
                    showLabel={false} />
                <Button variant='outline' onClick={(e) => openDriverSettings(e)}>Add Driver</Button>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>

            <div className="overflow-auto padding-1">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Driver</th>
                            <th></th>
                            <th>Active</th>
                        </tr>
                    </thead>
                    <tbody>
                        { filteredDrivers().map((d, index) => (
                        <tr>
                            <td>
                                <Driver driver={d} showStats={false}></Driver>
                            </td>
                            <td>
                                <Button variant='icon' onClick={(e) => openDriverSettings(e, d.driverId)}><SettingsIcon /></Button>
                            </td>
                            <td>
                                <Input name="Active" 
                                    type="checkbox" 
                                    checked={d.active}
                                    onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, d.driverId)}
                                    showLabel={false} />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
        </div>
    )
}

export default DriverTab;