import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from "react-router-dom";

import Button from '../../../atoms/Button/Button';
import Modal from '../../../atoms/Modal/Modal';
import PlaceGlyph from '../../../atoms/PlaceGlyph/PlaceGlyph';

import Skeleton from '../../../components/Skeleton/Skeleton';
import Loader from '../../../components/Loader/Loader';
import UserChip from '../../../components/UserChip/UserChip';

import SettingsIcon from '@mui/icons-material/Settings';
import './ResultsTab.css';

function ResultsTab({user, eventId}) {
    const [pointsModal, setPointsModal] = useState({show: false, submissionId: 0, adjustmentPoints: 0});
    
    const [form, setForm] = useState({});
    const [results, setResults] = useState([]);

    const [loader, setLoader] = useState({show: false, text: ''});

  
    useEffect(() => {
        console.log('ResultsTab: ', eventId);

        if(user.userId !== undefined && user.userId !== null && eventId !== undefined && eventId !== null) {
            fetchForm();
        }

    }, [user, eventId])

	const fetchForm = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				eventId: eventId
			})
		};

		fetch(apiUrl + `/app/formByEvent`, requestOptions)
			.then(response => response.json())
			.then(data => {					
				if(Object.keys(data).length > 0) {
					setForm(data);
					fetchResultsData(data.formId);
				} else {
				}
			})
			.catch((err) => console.log(err));
	}
	

	const fetchResultsData = (formId) => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
		
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				formId: formId
			})
		};

		setResults();	//Setting undefined for the skeleton loader

		fetch(apiUrl + `/app/results/pointsBreakdown`, requestOptions)
			.then(response => response.json())
			.then(data => {
                setResults(data);
		})
			.catch((err) => {
				setResults([]);		// resetting default to remove the skeleton
				console.log(err);
		});
	}


    
    
    const openPointsModal = (submissionId, adjustmentPoints) => {
        setPointsModal({show: true, submissionId: submissionId, adjustmentPoints: adjustmentPoints});
    }

    const closePointsModal = (action) => {
        if (action !== undefined && action === 'Set') {      

        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                submissionId: pointsModal.submissionId, 
                adjustmentPoints: pointsModal.adjustmentPoints
            })
        };

        setLoader({show: true, text: 'Setting points...'});
        fetch(apiUrl + "/app/submission/setPoints", requestOptions)
            .then(response => response.json())
            .then(() => {
            setPointsModal({show: false, submissionId: 0, adjustmentPoints: 0});
            setLoader({show: false});
            fetchResultsData();
        })
            .catch((err) => console.log(err));

        } else {
            setPointsModal({show: false, submissionId: 0, adjustmentPoints: 0});
        }
    }
    
        

    const handlePointsChange = (e) => {

        setPointsModal({show: true, submissionId: pointsModal.submissionId, adjustmentPoints: parseInt(e.target.value)});
    }


    return (
        <div className='event-details'>
            <Loader config={loader}></Loader>
      
            <Modal show={pointsModal.show} onHide={closePointsModal} closeOnOverlayClick={true}>
                <h2>Set Points</h2>
                <p>
                    <form onSubmit={e => {e.preventDefault();}}>
                        <label for="quantity">Additional Points:</label>
                        <input
                            type="number"
                            name="quantity"
                            min="0" max="50"
                            value={pointsModal.adjustmentPoints}
                            onChange={handlePointsChange} />
                    </form>
                </p>
                <div className="modal-footer">
                    <Button variant="secondary" onClick={closePointsModal}>Cancel</Button>
                    <Button variant="primary" onClick={() => closePointsModal('Set')}>Set</Button>
                </div>
            </Modal>
            
            {Object.keys(form).length > 0 && form.state !== 'Complete' && (
            <div className='dark-card note'>Results not yet finalized.</div>
            )}

			
			{results === undefined ? (<Skeleton variant='question' />) : (<> 
            <div className='dark-card'>
                {results.length > 0 && (
                <table className="table">
                    <thead style={{backgroundColor: 'var(--dark-2)'}}>
                        <tr>
                            <th>Place</th>
                            <th>Name</th>
                            <th>Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map(row => (
                        <tr className={row.user.userId===user.userId ? 'highlighted-user' : ''}>
                            <td><PlaceGlyph place={row.place} /></td>
                            <td style={{maxWidth: '200px'}}>
                                <UserChip user={row.user}></UserChip>
                            </td>
                            <td className="flex justify-content-between align-items-center">
                                <div>{row.totalPoints}</div>
                                {user.isAdmin && (
                                <Button variant="icon" onClick={() => openPointsModal(row.id, row.adjustmentPoints)}>
                                    <SettingsIcon fontSize="small" style={row.adjustmentPoints>0 ? {color: 'gold'} : {}} />
                                </Button>
                                )}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                )}
            </div>
			</>)}
        </div>
    )
}

export default ResultsTab;