import React from 'react';
import './Modal.css';

// Usage: <Modal className="btn-outlined" color="primary"></button>
// Variant:
//      (undefined)
//      primary
//      accent
//      warn

function Modal({children, show, onHide, closeOnOverlayClick, className, ...attributes}) {
    
    
    const handleStoppingEventPropagation = (e) => {
        e.stopPropagation();
    }

    const handleBackdropClick = (e) => {
        if(closeOnOverlayClick) {
            onHide();
        }
    }
    return (
        <div className={`modal-backdrop ${show ? 'show' : ''}`}
            onClick={handleBackdropClick}>
            <div className={`modal${className === undefined ? '' : ` ${className}`}`} {...attributes}
                onClick={handleStoppingEventPropagation}>
                {show && (<>
                    {children}
                </>)}
            </div>
        </div>
    )
}

export default Modal;