import React, { useState, useEffect, useReducer } from 'react';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import PictureChooser from '../PictureChooser/PictureChooser';
import UserChip from './UserChip';
import EditIcon from '@mui/icons-material/Edit';

import './UserChip.css';


function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload})`);   
    
    let newState = structuredClone(state);

    try {  
        switch(action.type) {
            case 'initialize':
                newState = action.payload;
                return newState;
  
            case 'update':
                if(action.payload.property === 'First Name') {
                    newState.firstName = action.payload.value;
                    
                } else if(action.payload.property === 'Last Name') {
                    newState.lastName = action.payload.value;
                    
                } else if(action.payload.property === 'Display Name') {
                    newState.displayName = action.payload.value;
                    
                } else if(action.payload.property === 'Color') {
                    newState.color = action.payload.value;
                    
                } else if(action.payload.property === 'Avatar URL') {
                    newState.avatarUrl = action.payload.value;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                    console.log(action.payload.property);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}
function CustomizeUserChip({user, setUser, onClose}) {
    // const [loader, setLoader] = useState({show: false, text: ''});
    const [message, setMessage] = useState([]);
    const [updatedUser, dispatch] = useReducer(reducer, {});
    const [canSave, setCanSave] = useState(false); //defined if in a modal
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});
    
    useEffect(() => {
        dispatch({type: 'initialize', payload: user});
    }, [user]);

    useEffect(() => {
        checkCanSave();
    }, [updatedUser])

    const colors = [
        '#E81D02', 
        '#025FE8', 
        '#B3E802', 
        '#933E32', 
        '#354A69', 
        '#7D9332', 
        '#325993', 
        '#5C6935',
        '#D6E7FF',
        'rgb(120, 0, 140)'
    ];
    
    const handleCustomizationChange = (property, value) => {
        console.log(`handleCustomizationChange [${property}] => ${value}`);

        dispatch({type: 'update', payload: {property, value}});

    }

    const isValid = () => {
        console.log('isValid', updatedUser);
        const newMessage = [];

        if (updatedUser.firstName === '' || updatedUser.firstName === undefined || updatedUser.firstName === null) {
            newMessage.push({field: 'First Name', text: 'First name must not be blank.'});
        } 
        
        if (updatedUser.lastName === '' || updatedUser.lastName === undefined || updatedUser.lastName === null) {
            newMessage.push({field: 'Last Name', text: 'Last name must not be blank.'});
        }
        
        if (updatedUser.displayName === '' || updatedUser.displayName === undefined || updatedUser.displayName === null) {
            newMessage.push({field: 'Display Name', text: 'Display name must not be blank.'});
        } 
        
        setMessage(newMessage);
        return newMessage.length === 0;
    }

    const checkCanSave = () => {
        let isDirty = JSON.stringify(user) !== JSON.stringify(updatedUser);
        if(isDirty) {
            setCanSave(true);
        } else {
            setCanSave(false);
        }
    }


    const openPictureChooser = () => {
        setPictureChooserConfig({show: true});
    }
    const closePictureChooser = (url) => {        
        if(url.length > 0) {
            handleCustomizationChange('Avatar URL', url);
        }
        setPictureChooserConfig({show: false});
    }

    const handleCancel = () => {
        if(onClose === undefined) { //defined if in a modal
            dispatch({type: 'initialize', payload: user});

        } else {
            // This doesn't work
            // if(isValid()) {
            //     onClose();
            // }
            onClose();
        }
    }

    const handleSave = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userId: user.userId, 
                firstName: updatedUser.firstName, 
                lastName: updatedUser.lastName, 
                displayName: updatedUser.displayName, 
                email: updatedUser.email, 
                avatarUrl: updatedUser.avatarUrl,
                color: updatedUser.color
            })
        };

        // setLoader({show: true, text: 'Saving profile...'});
        fetch(apiUrl + "/user/update", requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data !== undefined);
                if(data !== undefined) {
                    setUser(updatedUser);
                    setCanSave(false);
                    setMessage([]);

                    if(onClose!==undefined) { //defined if in a modal
                        onClose();
                    }
                }
            })
        .catch((err) => {
            console.log(err);
            // setLoader({show: false}); 
            setMessage([{field: 'Profile', text: 'Unable to save profile. Please contact support.'}]);
            
        });
    }

    return (<>
        <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>

        <form className="customizer"  onSubmit={e => {e.preventDefault();}}>
            <div className='flex align-items-center justify-content-between'>
                <h3 className='margin-0'>Profile</h3>
            </div>
            {message.filter(m => m.field === 'Profile')?.map(m => (<p className='warning'>{m.text}</p>))}


            <Input 
                type="fname" 
                value={updatedUser.firstName} 
                onChange={(e) => handleCustomizationChange('First Name', e.target.value)} 
                name="First Name" 
                withClear />
            {/* {message.filter(m => m.field === 'First Name')?.map(m => (<p className='warning'>{m.text}</p>))} */}

            <Input 
                type="lname" 
                value={updatedUser.lastName} 
                onChange={(e) => handleCustomizationChange('Last Name', e.target.value)} 
                name="Last Name" 
                withClear />
            {/* {message.filter(m => m.field === 'Last Name')?.map(m => (<p className='warning'>{m.text}</p>))} */}

            <Input 
                type="dname" 
                value={updatedUser.displayName} 
                onChange={(e) => handleCustomizationChange('Display Name', e.target.value)} 
                name="Display Name" 
                withClear />
            {/* {message.filter(m => m.field === 'Display Name')?.map(m => (<p className='warning'>{m.text}</p>))} */}

            <div>
                <label>Avatar</label>
                <div className='flex gap-1 align-items-center'>
                    <UserChip user={updatedUser} variant='avatar large'></UserChip>
                    <Button variant='icon outline circle small' onClick={openPictureChooser}>
                        <EditIcon />
                    </Button>
                </div>
            </div>

            <div className='grid'>
                <label>Color Picker</label>
                <div className='color-picker'>
                    {colors.map((color, i) => (<>
                        <input id={`color-swatch-${i}`} name="Color" type="radio" value={color}
                            onChange={(e) => handleCustomizationChange('Color', color)} 
                            checked={color===updatedUser.color}
                            />
                        <label for={`color-swatch-${i}`} className="color-swatch" style={{backgroundColor: color}}></label>
                    </>))}
                </div>
            </div>

            <div className='dark-card'>
                <div className='padding-05'>
                    <label>Preview</label>
                    <UserChip user={updatedUser}></UserChip>
                </div>
            </div>
            
            <div className='modal-footer'>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button variant="primary" onClick={handleSave} value="Save" className="save-btn" disabled={!canSave}>Save</Button>
            </div>
        </form>
    </>)
}


export default CustomizeUserChip