import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import Loader from '../../components/Loader/Loader';
import EventSettings from '../../components/EventSettings/EventSettings';

import ScheduleTab from './ScheduleTab/ScheduleTab';
import PicksTab from './PicksTab/PicksTab';
import StatsTab from './StatsTab/StatsTab';
import ResultsTab from './ResultsTab/ResultsTab';
import ResponsesTab from './ResponsesTab/ResponsesTab';

import TabGroup from '../../atoms/Tabs/TabGroup';
import Tab from '../../atoms/Tabs/Tab';
import Button from '../../atoms/Button/Button';

import SettingsIcon from '@mui/icons-material/Settings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import LockIcon from '@mui/icons-material/Lock';

import './Event.css';

function Event({user}) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [loader, setLoader] = useState({show: false, text: ''});
	const [picksOpen, setPicksOpen] = useState(false);

	const [event, setEvent] = useState({});
	const [form, setForm] = useState({});
	const [submission, setSubmission] = useState({});
	const [eventSettingsConfig, setEventSettingsConfig] = useState({show: false});
	
	const [activeTab, setActiveTab] = useState('Schedule');

	
	useEffect(() => {
		fetchEventDetails();
		
		if(searchParams.get('tab') !== undefined) {
			let validTabs = [
				'Schedule',
				'Stats',
				'Picks',
				'Results',
				'Points'
			];

			if(validTabs.includes(searchParams.get('tab'))) {
				setActiveTab(searchParams.get('tab'));
			}
		}
	}, [user]);

	
	useEffect(() => {
		determinePicksOpen();
	}, [form, submission]);

	const determinePicksOpen = () => {
		const currentTime = new Date();
		const submissionDeadlineDate = new Date(form.submissionDeadline);

		if(form.state === 'Live'
			&& currentTime > submissionDeadlineDate
			&& submission.state !== 'Submitted'
		) {
			setPicksOpen(true);
		} else {
			setPicksOpen(false);
		}
	}


	const handleTabChange = (newTab) => {
		setActiveTab(newTab);
		
		// Add the new query param value to the queryString
		searchParams.set("tab", newTab);
		
		// Enqueue navigation action that updates the queryString
		setSearchParams(searchParams);
	}


  
	const fetchEventDetails = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

		const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			eventId: searchParams.get('event'),
			userId: user.userId
		})
		};

		setLoader({show: true, text: 'Loading Event...'});
		fetch(apiUrl + `/app/eventDetails`, requestOptions)
		.then(response => response.json())
		.then(data => {
			setEvent(data.event);
			setForm({...data.form});
			setSubmission({...data.submission});

			setLoader({show: false});
		})
		.catch((err) => {
			console.log(err);
			setLoader({show: false});
		});
	}


	const isResponsesDisabled = () => {
		if(user.isAdmin === true) {
			return false;
		}

		if(submission.state === 'Submitted') {
			return false;
		} else {
			return true;
		}
	}


	const openEventSettings = () => {
		setEventSettingsConfig({
			show: true, 
			eventId: event.eventId
		});
	}

	const hideEventSettings = (action) => {
		setEventSettingsConfig({show: false});
		if(action === 'Reload') {
			fetchEventDetails();
		}
	}


	return ( 
		<div className="page-container">
		<Loader config={loader}></Loader>
		<EventSettings config={eventSettingsConfig} onHide={hideEventSettings}></EventSettings>


		<div className="flex justify-content-center padding-inline-1">
			<h1 className='single-line'>{event?.name}</h1>

			{user.isAdmin && (
			<Button variant='icon' onClick={openEventSettings}>
				<SettingsIcon fontSize="small" />
			</Button>
			)}
		</div>


		<TabGroup>
				<Tab label='Schedule' activeTab={activeTab} onClick={handleTabChange} />
				<Tab label='Stats' activeTab={activeTab} onClick={handleTabChange} />
				{user.userId !== null && (<>
					<Tab label='Picks' activeTab={activeTab} onClick={handleTabChange}>
						{picksOpen && (<div className='picks-live-circle'></div>)}
						{submission.state === 'Submitted' && (<div className='picks-submitted'><CheckCircleIcon /></div>)}
						Picks
					</Tab>
					<Tab label='Responses' activeTab={activeTab} onClick={handleTabChange} disabled={isResponsesDisabled()} />
					<Tab label='Results' activeTab={activeTab} onClick={handleTabChange} disabled={isResponsesDisabled()}>
						{form.state === 'Complete' && (<div className='form-complete'><SportsScoreIcon /></div>)}
						Results
					</Tab>
				</>)}
		</TabGroup>

		<div className='tab-outlet'>
			{activeTab === "Schedule" && (
				<ScheduleTab user={user} eventId={event.eventId}></ScheduleTab>
			)}
			
			{activeTab === "Stats" && (
				<StatsTab user={user} eventId={event.eventId}></StatsTab>
			)}
			
			{activeTab === "Picks" && (
				<PicksTab user={user} eventId={event.eventId} refreshEventInfo={fetchEventDetails}></PicksTab>
			)}
			
			{activeTab === "Responses" && (
				<ResponsesTab user={user} eventId={event.eventId}></ResponsesTab>
			)}  
			
			{activeTab === "Results" && (
				<ResultsTab user={user} eventId={event.eventId}></ResultsTab>
			)}
			</div>
		</div>
	);
}

export default Event;