import React, { useState, useEffect, useRef } from 'react';
import Button from '../Button/Button.js'
import BackspaceIcon from '@mui/icons-material/Backspace';
import './Input.css';

// Usage: <Input type="text"></Input>
//      
/**
 * Input wrapper component.
 * @param {string} className - Class passthrough
 */
function Input({name, type, value, onChange, className, withClear, showLabel, required, ...attributes}) {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState();

    useEffect(() => {
        if(type === 'date' && value !== undefined) {
            try {
                const utcTimestamp = value; // Datetime string with UTC timestamp            
                const utcDate = new Date(utcTimestamp); // Create a Date object from the UTC timestamp

                // Slice to support input (in local time)
                setInputValue(utcDate.toISOString().split('T')[0]); // YYYY-MM-DD
            } catch(e) {
                setInputValue(value);       // fallback previous value
            }

        } else if(type === 'datetime-local' && value !== undefined) {
            try {
                const utcTimestamp = value; // Datetime string with UTC timestamp
                const utcDate = new Date(utcTimestamp); // Create a Date object from the UTC timestamp
                const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000)); // Convert the UTC Date object to local time
                const localISOTime = localDate.toISOString(); // Get the ISO string representation of the local time

                // Slice to support input (in local time)
                setInputValue(localISOTime.slice(0, 16)); // YYYY-MM-DDThh:mm
            } catch(e) {
                setInputValue(value);       // fallback previous value
            }
        } else {
            setInputValue(value);
        }
    }, [type, value]);

    const isRequired = () => {
        //allsow implementation to just add a 'required' tag and this will understand that is true
        if(required !== undefined && required !== false) {
            return true
        } else {
            return false
        }
    }

    const handleOnChange = (e) => {
        setInputValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
    }
    const clearValue = () => {
        const inputElement = inputRef.current;
        const event = new Event('input', { bubbles: true, cancelable: true });

        // Set a new value to the input element
        inputElement.value = '';
        // Dispatch the event
        inputElement.dispatchEvent(event);

        handleOnChange(event);
    }

    return (
        <div className='ff-input'>
            {showLabel !== false && (<>
                {type !== 'radio' && (
                    <label for={name}>{name}{isRequired() && (<span style={{color: 'red'}}>*</span>)}</label>
                )}
            </>)}
            
            {type === 'textarea' ? (
                <textarea 
                    ref={inputRef}
                    id={name}
                    name={name}
                    value={inputValue}
                    onChange={handleOnChange}
                    required
                    {...attributes} />
            ) : (
                <input className={`${className !== undefined ? className : ''}`}
                    ref={inputRef}
                    id={name}
                    name={name}
                    type={type}
                    value={inputValue}
                    onChange={handleOnChange}
                    required
                    {...attributes} />
            )}
            
            {withClear !== undefined && (
                <Button className='clear-btn' variant='icon' onClick={clearValue}><BackspaceIcon fontSize='small' /></Button>
            )}
        </div>
    )
}

export default Input;